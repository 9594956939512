import React, { useRef, useState } from 'react'
import axios from 'axios'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../Firebase'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { subtractHours } from '../helpers/functions/regularFunctions'

const Signup = () => {
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const timezoneOffset = new Date().getTimezoneOffset() / 60
  const dateProp = subtractHours(new Date(), timezoneOffset)
    .toJSON()
    .slice(0, 10)

  async function handleSubmit(e) {
    e.preventDefault()

    await axios
      .post('https://fisher-fitness-91bf435fdc2b.herokuapp.com/user/', {
        email: emailRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        hydration: [
          {
            date: dateProp,
            cupsOfWaterDrank: 0,
          },
        ],
      })
      .then(function (response) {
        console.log(response)
        setDoc(doc(db, 'users', emailRef.current.value), {
          id: response.data._id,
          email: emailRef.current.value,
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords Do Not Match')
    }

    try {
      setError('')
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      navigate('/home')
    } catch (err) {
      setError('Failed To Create User')
      console.log(err)
    }

    setLoading(false)
  }

  return (
    <div>
      <Card style={{ margin: '25px 0' }}>
        <Card.Body>
          <h2 className='w-100 text-center'>Sign Up</h2>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id='first-name'>
              <Form.Label>First Name</Form.Label>
              <Form.Control type='text' ref={firstNameRef} required />
            </Form.Group>
            <Form.Group id='last-name'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type='text' ref={lastNameRef} required />
            </Form.Group>
            <Form.Group id='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' ref={emailRef} required />
            </Form.Group>
            <Form.Group id='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control type='password' ref={passwordRef} required />
            </Form.Group>
            <Form.Group id='password-confirm'>
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type='password' ref={passwordConfirmRef} required />
            </Form.Group>
            <Button
              disabled={loading}
              style={{ margin: '20px 0 0 0', width: '100%' }}
              type='submit'
            >
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className='basic-text'>
        Already Have An Account? <Link to='/login'>Log In</Link>
      </div>
    </div>
  )
}

export default Signup
