export function subtractHours(date, hours) {
  date.setHours(date.getHours() - hours)
  return date
}

export function resolveAfter1Second(x) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(x)
    }, 1500)
  })
}

export function arrayMax(arr) {
  return arr.reduce(function (p, v) {
    return p > v ? p : v
  })
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
