import React, { useState, useEffect } from 'react'
import Dropdowns from './exerciseEntryModalComponents/Dropdowns'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons'
import { apiPostCall } from '../../../../helpers/functions/proxyServer'

const ExerciseEntryModal = ({
  addExerciseModal,
  setAddExerciseModal,
  dateSearchInput,
  exercises,
  exerciseEntries,
  categoryChoice,
  dayOfWeek,
  submitted,
  setSubmitted,
  currentUserId,
}) => {
  const [updateNumbers, setUpdateNumbers] = useState(true)
  const [setsToPost, setSetsToPost] = useState([])
  const [submitWorkoutLoading, setSubmitWorkoutLoading] = useState(false)
  const [newInfo, setNewInfo] = useState(true)
  const [exerciseId, setExerciseId] = useState(-1)
  const [muscleGroupChoice, setMuscleGroupChoice] = useState('')
  const [setsChoice, setSetsChoice] = useState(
    categoryChoice === 'Cardio' ? 0 : 'Select # Of Sets'
  )
  const [durationChoice, setDurationChoice] = useState('')
  const [caloriesBurnedChoice, setCaloriesBurnedChoice] = useState('')
  const [exerciseChoice, setExerciseChoice] = useState('')
  const [weightUnitChoice, setWeightUnitChoice] = useState('Pounds')
  const [numIndividualSets, setNumIndividualSets] = useState([])

  const chooseExercise = (e) => {
    setExerciseChoice(e.target.textContent)
    setMuscleGroupChoice(
      exercises.filter(
        (a) => a.name.toLowerCase() === e.target.textContent.toLowerCase()
      )[0].muscleGroup
    )
    if (
      exercises
        .filter(
          (a) => a.name.toLowerCase() === e.target.textContent.toLowerCase()
        )[0]
        .category.toLowerCase() === 'cardio'
    ) {
      setSetsChoice(0)
    }
  }

  const chooseMuscleGroup = (e) => {
    setMuscleGroupChoice(e.target.textContent)
  }

  const chooseWeightUnit = (e) => {
    setWeightUnitChoice(e.target.textContent)
  }

  const chooseSets = (e) => {
    if (e.target.textContent === 'Select # Of Sets') {
      setSetsChoice(e.target.textContent)
      setSetsToPost([])
    } else {
      setSetsChoice(Number(e.target.textContent))
      let a = []
      for (let i = 0; i < Number(e.target.textContent); i++) {
        a.push({
          setNumber: i + 1,
          numberReps: 0,
          weight: 0,
          weightUnit: 'Pounds',
        })
      }
      setSetsToPost(a)
    }
  }

  const chooseExerEntryInfoPost = (e, a) => {
    setsToPost[a - 1] = e
    setNewInfo(!newInfo)
  }

  useEffect(() => {
    setExerciseId(
      exercises
        .filter((a) => a.name.toLowerCase() === exerciseChoice.toLowerCase())
        .map((b) => b._id)[0]
    )
  }, [exerciseChoice, exercises])

  async function handlePostExerciseEntrySubmit() {
    setSubmitWorkoutLoading(true)
    console.log(setsToPost)
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercise-entry',
      {
        user: currentUserId,
        exercise: exerciseId,
        exerciseDay: dayOfWeek,
        exerciseDatetime: `${dateSearchInput}T00:00:00.000Z`,
        sets: setsToPost,
        duration: Number(durationChoice),
        caloriesBurned: Number(caloriesBurnedChoice),
      }
    )
      .then(function (response) {
        console.log(response)
        setUpdateNumbers(!updateNumbers)
        setExerciseChoice('')
        setMuscleGroupChoice('')
        setDurationChoice('')
        setCaloriesBurnedChoice('')
        setNumIndividualSets([...Array(Number(0)).keys()].map((x) => x + 1))
        setSubmitWorkoutLoading(false)
        setAddExerciseModal(false)
        setSubmitted(!submitted)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div>
      <Modal
        show={addExerciseModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Modal.Title
              style={{ width: '80%', textAlign: 'center', marginLeft: '10%' }}
            >
              <div style={{ width: '100%' }}>
                <h1 style={{ textAlign: 'center', fontSize: '16px' }}>
                  {dayOfWeek}
                </h1>
                <h2 style={{ textAlign: 'center', fontSize: '16px' }}>
                  {dateSearchInput}
                </h2>
                <h3 style={{ textAlign: 'center', fontSize: '16px' }}>
                  {categoryChoice}
                </h3>
              </div>
            </Modal.Title>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', marginLeft: '10%' }}
              onClick={() => setAddExerciseModal(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: '0 0 10px 0' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Dropdowns
              categoryChoice={categoryChoice}
              exercises={exercises}
              exerciseEntries={exerciseEntries}
              exerciseChoice={exerciseChoice}
              setExerciseChoice={setExerciseChoice}
              chooseExercise={chooseExercise}
              durationChoice={durationChoice}
              setDurationChoice={setDurationChoice}
              caloriesBurnedChoice={caloriesBurnedChoice}
              setCaloriesBurnedChoice={setCaloriesBurnedChoice}
              chooseMuscleGroup={chooseMuscleGroup}
              muscleGroupChoice={muscleGroupChoice}
              chooseSets={chooseSets}
              setsChoice={setsChoice}
              setsToPost={setsToPost}
              numIndividualSets={numIndividualSets}
              weightUnitChoice={weightUnitChoice}
              chooseWeightUnit={chooseWeightUnit}
              chooseExerEntryInfoPost={chooseExerEntryInfoPost}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant='outline-dark'
                style={{ width: '80%', height: '40px', margin: '10px 0 0 10%' }}
                onClick={handlePostExerciseEntrySubmit}
              >
                Submit Exercise Entry
              </Button>
              {submitWorkoutLoading && (
                <div
                  className='fa-2x'
                  style={{
                    height: '40px',
                    width: '8%',
                    margin: '5px 0 0 1.5%',
                  }}
                >
                  <FontAwesomeIcon className='fa-spin' icon={faSpinner} />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ExerciseEntryModal
