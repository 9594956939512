import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faXmark,
  faEdit,
  faFloppyDisk,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { apiPostCall } from '../../../../helpers/functions/proxyServer'

const InfoModal = ({
  entryModal,
  setEntryModal,
  entryInfo,
  submitted,
  setSubmitted,
  individualSetsInfo,
  setIndividualSetsInfo,
}) => {
  async function postUpdatedExerciseEntry() {
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercise-entry/',
      {
        id: entryInfo._id,
        caloriesBurned: Number(newCaloriesBurnedChoice),
        exerciseDay: newDayChoice,
        exerciseDatetime: `${newDatetimeChoice}T00:00:00.000Z`,
        duration: Number(newDurationChoice),
        sets: individualSetsInfo,
      }
    )
      .then(function (response) {
        console.log(response)
        setConfirmSaveModal(false)
        setEntryModal(false)
        setSubmitted(!submitted)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  function undoChanges() {
    setNewDatetimeChoice(entryInfo.exerciseDatetime.split('T')[0])
    setNewCaloriesBurnedChoice(entryInfo.caloriesBurned)
    setNewDurationChoice(entryInfo.duration)
    setNewDayChoice(entryInfo.exerciseDay)
    setConfirmSaveModal(false)
  }

  function LaunchConfirmSaveModal(props) {
    return (
      <Modal {...props} size='sm' style={{ marginTop: '110%' }}>
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ textAlign: 'center', fontSize: '14px' }}
          >
            Are you sure? This will permanently change your exercise entry!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{
                width: '47%',
                height: '50px',
                marginLeft: '2%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={postUpdatedExerciseEntry}
            >
              Yes
            </Button>
            <Button
              id='undoChanges'
              style={{
                width: '47%',
                height: '50px',
                marginLeft: '2%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={undoChanges}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [editDuration, setEditDuration] = useState(false)
  const [editEntryDatetime, setEditEntryDatetime] = useState(false)
  const [editCaloriesBurned, setEditCaloriesBurned] = useState(false)
  const [editSets, setEditSets] = useState(false)
  const [confirmSaveModal, setConfirmSaveModal] = useState(false)
  const [newDurationChoice, setNewDurationChoice] = useState(entryInfo.duration)
  const [newDayChoice, setNewDayChoice] = useState(entryInfo.exerciseDay)
  const [newDatetimeChoice, setNewDatetimeChoice] = useState(
    entryInfo.exerciseDatetime.split('T')[0]
  )
  const [newCaloriesBurnedChoice, setNewCaloriesBurnedChoice] = useState(
    entryInfo.caloriesBurned
  )

  const handleDurationChange = (e) => {
    setNewDurationChoice(e.target.value)
  }

  const handleCaloriesBurnedChange = (e) => {
    setNewCaloriesBurnedChoice(e.target.value)
  }

  const handleDatetimeChange = (e) => {
    let a = e.target.value
    const d = new Date(
      a.split('-')[0],
      Number(a.split('-')[1]) - 1,
      a.split('-')[2]
    )
    setNewDatetimeChoice(e.target.value)
    if (a.length === 10) {
      setNewDayChoice(d.toLocaleString('en-us', { weekday: 'long' }))
    }
  }

  const handleEditIndividualSets = (a, item) => (e) => {
    setEditSets(true)
    if (item === 'reps') {
      setIndividualSetsInfo(
        individualSetsInfo.map((obj) => {
          if (obj.setNumber !== a.setNumber) {
            return obj
          } else {
            return {
              ...obj,
              numberReps: e.target.value,
            }
          }
        })
      )
    } else if (item === 'weight') {
      setIndividualSetsInfo(
        individualSetsInfo.map((obj) => {
          if (obj.setNumber !== a.setNumber) {
            return obj
          } else {
            return {
              ...obj,
              weight: e.target.value,
            }
          }
        })
      )
    }
    console.log(individualSetsInfo)
  }

  const handleAddSet = (e) => {
    setEditSets(true)
    setIndividualSetsInfo([
      ...individualSetsInfo,
      {
        exerciseEntry: { _id: entryInfo._id },
        setNumber: Number(individualSetsInfo.slice(-1)[0].setNumber) + 1,
        numberReps: 0,
        weight: 0,
        weightUnit: 'Pounds',
      },
    ])
  }

  const handleRemoveSet = (a) => (e) => {
    setEditSets(true)
    if (individualSetsInfo.length === 1) {
      setIndividualSetsInfo([
        {
          exerciseEntry: entryInfo._id,
          setNumber: 1,
          numberReps: 0,
          weight: 0,
          weightUnit: 'Pounds',
        },
      ])
    } else {
      setIndividualSetsInfo(
        individualSetsInfo.filter((b) => b.setNumber !== a.setNumber)
      )
    }
  }

  return (
    <div>
      <LaunchConfirmSaveModal
        show={confirmSaveModal}
        onHide={() => setConfirmSaveModal(false)}
      />
      <Modal
        show={entryModal}
        onHide={() => setEntryModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Modal.Title
              style={{ width: '60%', textAlign: 'center', marginLeft: '20%' }}
            >
              {`${entryInfo.exercise.name}`}
            </Modal.Title>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', marginLeft: '10%' }}
              onClick={() => setEntryModal(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0 0 0' }}>
          <div>
            <ul
              style={{
                listStyleType: 'none',
                padding: '0',
                textAlign: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <li style={{ marginTop: '2px' }}>
                    {!editEntryDatetime ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '90%',
                          marginLeft: '10%',
                        }}
                      >
                        <p
                          style={{
                            width: '72%',
                            fontSize: '14px',
                            height: '14px',
                            paddingTop: '4px',
                          }}
                        >
                          {`${newDayChoice}, ${newDatetimeChoice}`}
                        </p>
                        <Button
                          style={{
                            width: '15%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() => setEditEntryDatetime(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '90%',
                          marginLeft: '17%',
                        }}
                      >
                        <InputGroup style={{ width: '65%', height: '30px' }}>
                          <InputGroup.Text
                            style={{
                              fontSize: '14px',
                              width: '45%',
                              height: '30px',
                              textAlign: 'center',
                              padding: '0 0 0 7px',
                            }}
                          >
                            {newDayChoice}
                          </InputGroup.Text>
                          <Form.Control
                            style={{
                              fontSize: '14px',
                              width: '55%',
                              height: '30px',
                              textAlign: 'center',
                            }}
                            aria-label='datetime'
                            onChange={handleDatetimeChange}
                            value={newDatetimeChoice}
                          />
                        </InputGroup>
                        <Button
                          style={{
                            width: '15%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '1%',
                          }}
                          variant='outline-dark'
                          onClick={() =>
                            setEditEntryDatetime(false) +
                            setNewDatetimeChoice(
                              entryInfo.exerciseDatetime.split('T')[0]
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}
                  </li>
                  <li style={{ marginTop: '2px' }}>
                    {!editDuration ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '90%',
                          marginLeft: '10%',
                        }}
                      >
                        <p
                          style={{
                            width: '72%',
                            fontSize: '14px',
                            height: '14px',
                            paddingTop: '4px',
                          }}
                        >
                          {`${newDurationChoice} minutes`}
                        </p>
                        <Button
                          style={{
                            width: '15%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() => setEditDuration(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '90%',
                          marginLeft: '17%',
                        }}
                      >
                        <InputGroup style={{ width: '65%', height: '30px' }}>
                          <Form.Control
                            style={{
                              fontSize: '14px',
                              width: '35%',
                              height: '30px',
                              textAlign: 'center',
                            }}
                            aria-label='duration'
                            onChange={handleDurationChange}
                            value={newDurationChoice}
                          />
                          <InputGroup.Text
                            style={{
                              fontSize: '14px',
                              width: '65%',
                              height: '30px',
                              textAlign: 'center',
                              padding: '0 0 0 7px',
                            }}
                          >
                            minutes
                          </InputGroup.Text>
                        </InputGroup>
                        <Button
                          style={{
                            width: '15%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '1%',
                          }}
                          variant='outline-dark'
                          onClick={() =>
                            setEditDuration(false) +
                            setNewDurationChoice(entryInfo.duration)
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}
                  </li>
                  <li style={{ marginTop: '2px' }}>
                    {!editCaloriesBurned ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '90%',
                          marginLeft: '10%',
                        }}
                      >
                        <p
                          style={{
                            width: '72%',
                            fontSize: '14px',
                            height: '14px',
                            paddingTop: '4px',
                          }}
                        >
                          {newCaloriesBurnedChoice} calories
                        </p>
                        <Button
                          style={{
                            width: '15%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() => setEditCaloriesBurned(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '90%',
                          marginLeft: '17%',
                        }}
                      >
                        <InputGroup style={{ width: '65%', height: '30px' }}>
                          <Form.Control
                            style={{
                              fontSize: '14px',
                              width: '35%',
                              height: '30px',
                              textAlign: 'center',
                            }}
                            aria-label='calories-burned'
                            onChange={handleCaloriesBurnedChange}
                            value={newCaloriesBurnedChoice}
                          />
                          <InputGroup.Text
                            style={{
                              fontSize: '14px',
                              width: '65%',
                              height: '30px',
                              textAlign: 'center',
                              padding: '0 0 0 7px',
                            }}
                          >
                            calories
                          </InputGroup.Text>
                        </InputGroup>
                        <Button
                          style={{
                            width: '15%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '1%',
                          }}
                          variant='outline-dark'
                          onClick={() =>
                            setEditCaloriesBurned(false) +
                            setNewCaloriesBurnedChoice(entryInfo.caloriesBurned)
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}
                  </li>
                  <li style={{ marginTop: '2px' }}>
                    {entryInfo.exercise.category === 'weight lifting' && (
                      <div>
                        {individualSetsInfo.map((a) => (
                          <div
                            key={a.setNumber}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '98%',
                              marginLeft: '2%',
                            }}
                          >
                            <h1
                              style={{
                                fontSize: '16px',
                                marginTop: '5px',
                                width: '15%',
                              }}
                            >{`Set ${a.setNumber}:`}</h1>
                            <InputGroup
                              style={{
                                width: '40%',
                                height: '30px',
                                marginLeft: '2px',
                              }}
                            >
                              <Form.Control
                                style={{
                                  fontSize: '14px',
                                  width: '35%',
                                  height: '30px',
                                  textAlign: 'center',
                                }}
                                aria-label='duration'
                                value={a.numberReps}
                                onChange={handleEditIndividualSets(a, 'reps')}
                              />
                              <InputGroup.Text
                                style={{
                                  fontSize: '14px',
                                  width: '65%',
                                  height: '30px',
                                  textAlign: 'center',
                                  padding: '0 0 0 7px',
                                }}
                              >
                                reps
                                <Button
                                  style={{
                                    width: '45%',
                                    height: '30px',
                                    border: '0',
                                    padding: '0',
                                    marginLeft: '25%',
                                  }}
                                  variant='outline-dark'
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                            <InputGroup
                              style={{
                                width: '40%',
                                height: '30px',
                                marginLeft: '2px',
                              }}
                            >
                              <Form.Control
                                style={{
                                  fontSize: '14px',
                                  width: '35%',
                                  height: '30px',
                                  textAlign: 'center',
                                }}
                                aria-label='duration'
                                value={a.weight}
                                onChange={handleEditIndividualSets(a, 'weight')}
                              />
                              <InputGroup.Text
                                style={{
                                  fontSize: '14px',
                                  width: '65%',
                                  height: '30px',
                                  textAlign: 'center',
                                  padding: '0 0 0 7px',
                                }}
                              >
                                {a.weightUnit}
                                <Button
                                  style={{
                                    width: '40%',
                                    height: '30px',
                                    border: '0',
                                    padding: '0',
                                    marginLeft: '5%',
                                  }}
                                  variant='outline-dark'
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                            <Button
                              className='border-0'
                              variant='outline-dark'
                              style={{
                                height: '30px',
                                border: '0',
                                padding: '0 7px',
                                margin: '0 3px',
                              }}
                              id={a.setNumber}
                              onClick={handleRemoveSet(a)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          </div>
                        ))}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '90%',
                            margin: '15px 5% 0',
                          }}
                        >
                          <Button
                            style={{
                              fontSize: '12px',
                              width: '1000%',
                            }}
                            variant='outline-dark'
                            onClick={handleAddSet}
                          >
                            Add Set
                          </Button>
                        </div>
                        {(editEntryDatetime ||
                          editCaloriesBurned ||
                          editDuration ||
                          editSets) && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '90%',
                              margin: '15px 5% 0',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: '90%',
                                border: '0',
                                padding: '0',
                                margin: '10px 0',
                              }}
                              variant='outline-dark'
                              onClick={() =>
                                setEditEntryDatetime(false) +
                                setEditCaloriesBurned(false) +
                                setEditDuration(false) +
                                setEditSets(false) +
                                setConfirmSaveModal(true)
                              }
                            >
                              <FontAwesomeIcon
                                className='fa-3x'
                                icon={faFloppyDisk}
                              />
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InfoModal
