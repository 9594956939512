import React from 'react'
import Card from 'react-bootstrap/Card'

const Users = ({
  dateSearchInput,
  mealEntries,
  exerciseEntries,
  foods,
  currentUserFirstName,
  currentUserLastName,
}) => {
  const totalCaloriesBurned = exerciseEntries
    .filter((a) => String(a.exerciseDatetime).includes(dateSearchInput))
    .map((b) => b.caloriesBurned)
    .reduce(function (a, b) {
      return a + b
    }, 0)

  const totalCalories = mealEntries
    .filter((a) => String(a.mealDatetime).includes(dateSearchInput))
    .map(
      (b) =>
        b.servingsEaten *
        foods.filter((c) => c._id === b.food._id).map((d) => d.calories)
    )
    .reduce(function (a, b) {
      return a + b
    }, 0)

  const netCalories = Math.round(totalCalories - totalCaloriesBurned)

  const dayOfWeek = mealEntries
    .filter((a) => String(a.mealDatetime).includes(dateSearchInput))
    .map((b) => b.mealDay)[0]
  return (
    <div style={{ width: '90%', margin: '5px 5% 0' }}>
      <Card style={{ backgroundColor: '#c0f9dd', width: '100%' }}>
        <Card.Body className='text-center'>
          <Card.Title>
            {currentUserFirstName} {currentUserLastName}
          </Card.Title>
          <Card.Subtitle>
            {dayOfWeek ? dayOfWeek + ', ' + dateSearchInput : null}
          </Card.Subtitle>
          <Card.Text>Net Calories: {netCalories}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Users
