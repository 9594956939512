import React from 'react'
import ExerciseTotals from './DailyTotalTypes/ExerciseTotals'
import MealTotals from './DailyTotalTypes/MealTotals'

const DailyTotals = ({
  mealEntries,
  exerciseEntries,
  dateSearchInput,
  foods,
  homemadeFoods,
  exercises,
  user,
  currentUserId,
}) => {
  return (
    <div style={{ width: '90%', margin: '5px 5%' }}>
      <MealTotals
        mealEntries={mealEntries}
        foods={foods}
        dateSearchInput={dateSearchInput}
        homemadeFoods={homemadeFoods}
        user={user}
        currentUserId={currentUserId}
      />
      <ExerciseTotals
        exercises={exercises}
        exerciseEntries={exerciseEntries}
        dateSearchInput={dateSearchInput}
      />
    </div>
  )
}

export default DailyTotals
