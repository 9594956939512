import React from 'react'
import FoodDropdown from './Dropdowns/FoodDropdown'
import BrandDropdown from './Dropdowns/BrandDropdown'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

const Dropdowns = ({ setProteinChoice, setCarbsChoice, setFatChoice,  setCaloriesChoice, foodChoice, setFoodChoice, chooseFood, foods, brandChoice, setBrandChoice, chooseBrand, setServingSizeUnitChoice,  setServingSizeChoice, setDescriptionChoice, proteinChoice, carbsChoice, fatChoice, caloriesChoice, servingSizeChoice, servingSizeUnitChoice, descriptionChoice }) => {

  const handleProteinSearchChange = (e) => {
    setProteinChoice(e.target.value)
  }
  
  const handleCarbsSearchChange = (e) => {
    setCarbsChoice(e.target.value)
  }

  const handleFatSearchChange = (e) => {
    setFatChoice(e.target.value)
  }

  const handleCaloriesSearchChange = (e) => {
    setCaloriesChoice(e.target.value)
  }

  const handleServingSizeSearchChange = (e) => {
    setServingSizeChoice(e.target.value)
  }

  const handleServingSizeUnitSearchChange = (e) => {
    setServingSizeUnitChoice(e.target.value)
  }

  const handleDescriptionSearchChange = (e) => {
    setDescriptionChoice(e.target.value)
  }

  return (
    <div style = {{ width: '100%' }}>
      <BrandDropdown
        brandChoice = {brandChoice}
        setBrandChoice = {setBrandChoice}
        chooseBrand = {chooseBrand}
        foods = {foods}
      />
      <FoodDropdown
        foodChoice = {foodChoice}
        setFoodChoice = {setFoodChoice}
        chooseFood = {chooseFood}
        brandChoice = {brandChoice}
        foods = {foods}
      />
      <InputGroup style = {{ margin: '10px 0' }}>
        <InputGroup.Text style = {{ fontSize: '14px' }}>Add Macros {'(grams)'}</InputGroup.Text>
        <Form.Control style = {{ fontSize: '14px' }} aria-label="Protein" placeholder = 'Protein...' onChange = {handleProteinSearchChange} value = {proteinChoice}/>
        <Form.Control style = {{ fontSize: '14px' }} aria-label="Carbs" placeholder = 'Carbs...' onChange = {handleCarbsSearchChange} value = {carbsChoice}/>
        <Form.Control aria-label="Fat" placeholder = 'Fat...' onChange = {handleFatSearchChange} value = {fatChoice}/>
      </InputGroup>
      <InputGroup style = {{ margin: '10px 0' }}>
        <InputGroup.Text style = {{ fontSize: '14px' }}>Add Total Calories</InputGroup.Text>
        <Form.Control style = {{ fontSize: '14px' }} aria-label="Calories" placeholder = 'Calories...' onChange = {handleCaloriesSearchChange} value = {caloriesChoice}/>
      </InputGroup>
      <InputGroup style = {{ margin: '10px 0' }}>
          <InputGroup.Text style = {{ fontSize: '14px' }}>Add Servings Info</InputGroup.Text>
          <Form.Control style = {{ fontSize: '14px' }} aria-label="ServingSize" placeholder = 'Serving Size...' onChange = {handleServingSizeSearchChange} value = {servingSizeChoice}/>
          <Form.Control style = {{ fontSize: '14px' }} aria-label="ServingSizeUnit" placeholder = 'Unit...' onChange = {handleServingSizeUnitSearchChange} value = {servingSizeUnitChoice}/>
      </InputGroup>
      <InputGroup style = {{ margin: '5px 0' }}>
          <InputGroup.Text style = {{ fontSize: '14px' }}>Add Any Additional Info</InputGroup.Text>
          <Form.Control style = {{ fontSize: '14px' }} aria-label="description" onChange = {handleDescriptionSearchChange} value = {descriptionChoice} placeholder = 'Description...'/>
      </InputGroup>
    </div>
  )
}

export default Dropdowns
