import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { toTitleCase } from '../../../../../helpers/functions/regularFunctions'

const AddMeal = ({
  meals,
  currentUserId,
  categoryChoice,
  dayOfWeek,
  dateSearchInput,
  apiPostCall,
  setAddMealModal,
  setSubmitted,
  submitted,
}) => {
  const [submitReady, setSubmitReady] = useState(false)
  const [selectedMeal, setSelectedMeal] = useState([])

  async function handlePostMealToMealEntry() {
    for (let i = 0; i < selectedMeal[0].foodList.length; i++) {
      await apiPostCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal-entry/',
        {
          user: currentUserId,
          food: selectedMeal[0].foodList[i].food,
          category: categoryChoice,
          mealDay: dayOfWeek,
          mealDatetime: `${dateSearchInput}`,
          servingsEaten: selectedMeal[0].foodList[i].servings,
        }
      )
        .then(function (response) {
          console.log(response)
          setAddMealModal(false)
          setSubmitted(!submitted)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  const handleSelectMeal = (e) => {
    setSelectedMeal(meals.filter((a) => a._id === e.currentTarget.id))
    setSubmitReady(true)
  }

  return (
    <div>
      <h1 style={{ fontSize: '14px', textAlign: 'center', marginTop: '20px' }}>
        Choose One Of Your Meals Below!
      </h1>
      <div>
        <ul
          style={{
            listStyleType: 'none',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxHeight: '250px',
            overflowY: 'scroll',
            marginTop: '20px',
          }}
        >
          {meals
            .filter((c) => !selectedMeal.map((b) => b._id).includes(c._id))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((a) => (
              <li
                key={a._id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '2px 2%',
                  width: '96%',
                  height: '40px',
                }}
              >
                <Button
                  variant='outline-dark'
                  style={{ width: '10%', marginLeft: '2%' }}
                  id={a._id}
                  onClick={handleSelectMeal}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Card
                  className='border-0'
                  style={{
                    width: '40%',
                    margin: '10px 0 10px 2%',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '20px',
                  }}
                >
                  {toTitleCase(a.name)}
                </Card>
                <Card
                  className='border-0'
                  style={{
                    width: '25%',
                    margin: '10px 0 10px 10%',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '20px',
                  }}
                >
                  {`${Math.round(
                    a.foodList
                      .map((a) => a.food.calories * a.servings)
                      .reduce(function (a, b) {
                        return a + b
                      }, 0)
                  )} Calories`}
                </Card>
              </li>
            ))}
        </ul>
      </div>
      <div
        style={{
          width: '96%',
          margin: '20px 0 0 2%',
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        {submitReady && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: '100%',
              marginTop: '25px',
            }}
          >
            <p>{selectedMeal[0].name}</p>
            <p>{selectedMeal[0].description}</p>
            <Button variant='outline-dark' onClick={handlePostMealToMealEntry}>
              Submit Meal Entry
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddMeal
