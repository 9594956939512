import React, { useState } from 'react'
import InfoModal from '../ExerciseTypes/modals/InfoModal'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faPlus,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons'

import ExerciseEntryModal from './modals/ExerciseEntryModal'

const Cardio = ({
  dateSearchInput,
  exercises,
  exerciseEntries,
  exerciseEntryInfo,
  chooseDeleteExerciseEntryId,
  dayOfWeek,
  submitted,
  setSubmitted,
  currentUserId,
}) => {
  const [addExerciseModal, setAddExerciseModal] = useState(false)
  const [entryModal, setEntryModal] = useState(false)
  const [entryInfo, setEntryInfo] = useState([])

  const deleteConfirmation = (e) => {
    chooseDeleteExerciseEntryId(e.currentTarget.id)
  }

  const cardio = exerciseEntries
    .filter(
      (c) =>
        exercises
          .filter((a) => a.category.toLowerCase() === 'cardio')
          .map((b) => b._id)
          .indexOf(c.exercise._id) !== -1
    )
    .filter((d) => d.exerciseDatetime.includes(dateSearchInput))

  const cardioItems = cardio.map((item) => ({
    text: exercises
      .filter((a) => a._id === item.exercise._id)
      .map((n) => item.duration + ' minutes of ' + n.name),
    id: item._id,
  }))

  const totalCaloriesBurned = cardio
    .filter((a) => String(a.exerciseDatetime).includes(dateSearchInput))
    .map((b) => b.caloriesBurned)
    .reduce(function (a, b) {
      return a + b
    }, 0)

  const totalMinutes = cardio
    .filter((a) => String(a.exerciseDatetime).includes(dateSearchInput))
    .map((b) => b.duration)
    .reduce(function (a, b) {
      return a + b
    }, 0)

  const handleAddExerciseEntry = (e) => {
    setAddExerciseModal(true)
  }

  const handleGetEntryInfo = (e) => {
    setEntryModal(true)
    setEntryInfo(exerciseEntries.filter((a) => a._id === e.currentTarget.id)[0])
  }

  return (
    <div style={{ width: '100%', margin: '5px 0' }}>
      <ExerciseEntryModal
        addExerciseModal={addExerciseModal}
        setAddExerciseModal={setAddExerciseModal}
        currentUserId={currentUserId}
        categoryChoice={'Cardio'}
        exercises={exercises}
        exerciseEntries={exerciseEntries}
        exerciseEntryInfo={exerciseEntryInfo}
        dateSearchInput={dateSearchInput}
        chooseDeleteExerciseEntryId={chooseDeleteExerciseEntryId}
        dayOfWeek={dayOfWeek}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
      <Card style={{ backgroundColor: '#c0e2f9' }}>
        <Card.Title className='text-center'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h1 style={{ fontSize: '20px', marginLeft: '10%', width: '80%' }}>
              Cardio
            </h1>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', color: '#85b1ff' }}
              onClick={handleAddExerciseEntry}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </Card.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceAround',
            margin: '0 0 15px 0',
          }}
        >
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 12%',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Calories Burned
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {totalCaloriesBurned}
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 12% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Total Minutes
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {totalMinutes}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
        <ul style={{ listStyleType: 'none', padding: '0' }}>
          {cardioItems.map((a) => (
            <div
              key={a.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {entryModal && (
                <div>
                  <InfoModal
                    exercises={exercises}
                    exerciseEntries={exerciseEntries}
                    entryModal={entryModal}
                    setEntryModal={setEntryModal}
                    entryInfo={entryInfo}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                  />
                </div>
              )}
              <Button
                className='border-0 fa-light'
                variant='outline-dark'
                style={{ width: '10%', color: '#85b1ff' }}
                id={a.id}
                onClick={handleGetEntryInfo}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </Button>
              <li
                style={{
                  height: '32px',
                  width: '85%',
                  margin: '0 1%',
                  fontSize: '14px',
                  paddingTop: '8px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {a.text}
              </li>
              <Button
                className='border-0'
                variant='outline-dark'
                style={{ width: '10%', marginLeft: '2%' }}
                id={a.id}
                onClick={deleteConfirmation}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </div>
          ))}
        </ul>
      </Card>
    </div>
  )
}

export default Cardio
