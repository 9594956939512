import React, { useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

const ExerciseDropdown = ({
  exercises,
  exerciseChoice,
  setExerciseChoice,
  chooseExercise,
}) => {
  const startingExerciseList = exercises.map((a) => a.name)

  const [updatedExerciseList, setUpdatedExerciseList] = useState([])

  const [openExerciseDropdown, setOpenExerciseDropdown] = useState(false)

  const handleExerciseMenu = (e) => {
    setOpenExerciseDropdown(false)
    chooseExercise(e)
  }

  const handleExerciseSearchChange = (e) => {
    const currentExerciseList = startingExerciseList
      .map((a) => a.toLowerCase())
      .filter((a) => a.includes(e.target.value.toLowerCase()))

    setExerciseChoice(e.target.value)

    setUpdatedExerciseList([...new Set(currentExerciseList)])

    if (e.target.value.length > 0) {
      setOpenExerciseDropdown(true)
    } else {
      setOpenExerciseDropdown(false)
    }
  }

  const handleExerciseSearchClick = (e) => {
    setOpenExerciseDropdown(!openExerciseDropdown)
    setUpdatedExerciseList([...new Set(startingExerciseList)])
  }

  return (
    <div style={{ marginTop: '25px' }}>
      <InputGroup
        style={{ width: '90%', margin: '0 5%' }}
        onClick={handleExerciseSearchClick}
      >
        <InputGroup.Text id='inputGroup-sizing-default'>
          Exercise
        </InputGroup.Text>
        <Form.Control
          aria-label='Default'
          aria-describedby='inputGroup-sizing-default'
          placeholder='Type Exercise Name Here...'
          style={{ fontSize: '14px' }}
          value={exerciseChoice}
          onChange={handleExerciseSearchChange}
        />
        {openExerciseDropdown ? (
          <ul
            style={{
              position: 'absolute',
              listStyleType: 'none',
              padding: '0',
              margin: '37.81px 0 0 85px',
              alignItems: 'center',
              zIndex: '1',
            }}
          >
            {updatedExerciseList.map((a, index) => (
              <li key={index} style={{ margin: '0', height: '40px' }}>
                <Card
                  style={{ height: '100%', width: '190.81px', padding: '0' }}
                  onClick={handleExerciseMenu}
                >
                  <Card.Body
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {a}
                  </Card.Body>
                </Card>
              </li>
            ))}
          </ul>
        ) : null}
      </InputGroup>
    </div>
  )
}

export default ExerciseDropdown
