import React, { useState } from 'react'
import SetsDropdown from './Dropdowns/SetsDropdown'
import ExerciseDropdown from './Dropdowns/ExerciseDropdown'
import MuscleGroupDropdown from './Dropdowns/MuscleGroupDropdown'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

const Dropdowns = ({
  exercises,
  exerciseChoice,
  setExerciseChoice,
  chooseExercise,
  durationChoice,
  setDurationChoice,
  caloriesBurnedChoice,
  setCaloriesBurnedChoice,
  setsChoice,
  chooseSets,
  chooseMuscleGroup,
  muscleGroupChoice,
  numIndividualSets,
  weightUnitChoice,
  chooseWeightUnit,
  maxExerciseEntryInfoId,
  chooseExerEntryInfoPost,
  setsToPost,
  categoryChoice,
}) => {
  const [openMuscleGroupDropdown, setOpenMuscleGroupDropdown] = useState(false)
  const [openExerciseDropdown, setOpenExerciseDropdown] = useState(false)

  const chooseDropdown = (a) => {
    if (a === 'closeExercise') {
      setOpenExerciseDropdown(false)
    } else if (a === 'closeMuscle') {
      setOpenMuscleGroupDropdown(false)
    } else if (a === 'closeBoth') {
      setOpenExerciseDropdown(false)
      setOpenMuscleGroupDropdown(false)
    }
  }

  const handleDurationChange = (e) => {
    setDurationChoice(e.target.value)
  }

  const handleCaloriesBurnedChange = (e) => {
    setCaloriesBurnedChoice(e.target.value)
  }

  return (
    <div style={{ width: '100%', margin: '0' }}>
      {categoryChoice === 'Cardio' ? (
        <div>
          <ExerciseDropdown
            exercises={exercises.filter(
              (a) => a.category.toLowerCase() === 'cardio'
            )}
            chooseExercise={chooseExercise}
            exerciseChoice={exerciseChoice}
            setExerciseChoice={setExerciseChoice}
            openExerciseDropdown={openExerciseDropdown}
            setOpenExerciseDropdown={setOpenExerciseDropdown}
            chooseDropdown={chooseDropdown}
          />
          <InputGroup style={{ width: '80%', margin: '10px 10% 0' }}>
            <InputGroup.Text id='inputGroup-sizing-default'>
              Duration
            </InputGroup.Text>
            <Form.Control
              aria-label='Default'
              aria-describedby='inputGroup-sizing-default'
              placeholder='Workout Duration...'
              style={{ fontSize: '14px' }}
              value={durationChoice}
              onChange={handleDurationChange}
            />
          </InputGroup>
          <InputGroup style={{ width: '80%', margin: '10px 10% 0' }}>
            <InputGroup.Text id='inputGroup-sizing-default'>
              Calories
            </InputGroup.Text>
            <Form.Control
              aria-label='Default'
              aria-describedby='inputGroup-sizing-default'
              placeholder='Calories Burned...'
              style={{ fontSize: '14px' }}
              value={caloriesBurnedChoice}
              onChange={handleCaloriesBurnedChange}
            />
          </InputGroup>
        </div>
      ) : categoryChoice === 'Weight Lifting' ? (
        <div>
          <div>
            <ExerciseDropdown
              exercises={exercises.filter(
                (a) => a.category.toLowerCase() === 'weight lifting'
              )}
              chooseExercise={chooseExercise}
              exerciseChoice={exerciseChoice}
              setExerciseChoice={setExerciseChoice}
              openExerciseDropdown={openExerciseDropdown}
              setOpenExerciseDropdown={setOpenExerciseDropdown}
              chooseDropdown={chooseDropdown}
            />
            <MuscleGroupDropdown
              muscleGroupChoice={muscleGroupChoice}
              chooseMuscleGroup={chooseMuscleGroup}
              exercises={exercises}
              openMuscleGroupDropdown={openMuscleGroupDropdown}
              setOpenMuscleGroupDropdown={setOpenMuscleGroupDropdown}
              chooseDropdown={chooseDropdown}
            />
            <InputGroup style={{ width: '80%', margin: '10px 10% 0' }}>
              <InputGroup.Text id='inputGroup-sizing-default'>
                Duration
              </InputGroup.Text>
              <Form.Control
                aria-label='Default'
                aria-describedby='inputGroup-sizing-default'
                placeholder='Workout Duration...'
                style={{ fontSize: '14px' }}
                value={durationChoice}
                onChange={handleDurationChange}
              />
            </InputGroup>
            <InputGroup style={{ width: '80%', margin: '10px 10% 0' }}>
              <InputGroup.Text id='inputGroup-sizing-default'>
                Calories
              </InputGroup.Text>
              <Form.Control
                aria-label='Default'
                aria-describedby='inputGroup-sizing-default'
                placeholder='Calories Burned...'
                style={{ fontSize: '14px' }}
                value={caloriesBurnedChoice}
                onChange={handleCaloriesBurnedChange}
              />
            </InputGroup>
            <SetsDropdown
              setsChoice={setsChoice}
              chooseSets={chooseSets}
              numIndividualSets={numIndividualSets}
              chooseWeightUnit={chooseWeightUnit}
              weightUnitChoice={weightUnitChoice}
              maxExerciseEntryInfoId={maxExerciseEntryInfoId}
              chooseExerEntryInfoPost={chooseExerEntryInfoPost}
              setsToPost={setsToPost}
              chooseDropdown={chooseDropdown}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Dropdowns
