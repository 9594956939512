import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTrashAlt,
  faCaretDown,
  faEdit
} from '@fortawesome/free-solid-svg-icons'

import InfoModals from './modals/InfoModals'
import MealEntryModal from './modals/MealEntryModal'

const Lunch = ({
  foods,
  meals,
  mealEntries,
  units,
  currentUserId,
  dateSearchInput,
  dayOfWeek,
  chooseDeleteMealEntryId,
  submitted,
  setSubmitted,
}) => {
  const deleteConfirmation = (e) => {
    chooseDeleteMealEntryId(e.currentTarget.id)
  }

  const lunchMeals = mealEntries
    .filter(
    (c) =>
      c.category === 'Lunch' &&
      String(c.mealDatetime).includes(dateSearchInput)
  )

  const lunchItems = lunchMeals.map((item) => (
    !item.isMeal ? {
    isMeal: false,
    text: foods
      .filter((a) => a._id === item.food._id)
      .map(
        (n) =>
          (item.servingsEaten * n.servingSize).toFixed(1) +
          ' ' +
          (units.includes(n.servingSizeUnit.toLowerCase())
            ? n.servingSizeUnit.toLowerCase() + ' '
            : '') +
          n.name
      ),
    calories: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.calories))[0],
    protein: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.proteinGrams))[0],
    carbs: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.carbsGrams))[0],
    fat: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.fatGrams))[0],
    id: item._id,
  } : {
    isMeal: true,
    text: meals
      .filter((a) => a._id === item.meal._id)
      .map((n) => (`${item.servingsEaten} ${n.name}`)),
    calories: meals
      .filter((a) => a._id === item.meal._id)[0].foodList
      .map((b) => b.food.calories*b.servings*item.servingsEaten)
      .reduce(function (c, d) {
        return c + d
      }, 0),
    protein: meals
      .filter((a) => a._id === item.meal._id)[0].foodList
      .map((b) => b.food.proteinGrams*b.servings*item.servingsEaten)
      .reduce(function (c, d) {
        return c + d
      }, 0),
    carbs: meals
      .filter((a) => a._id === item.meal._id)[0].foodList
      .map((b) => b.food.carbsGrams*b.servings*item.servingsEaten)
      .reduce(function (c, d) {
        return c + d
      }, 0),
    fat: meals
      .filter((a) => a._id === item.meal._id)[0].foodList
      .map((b) => b.food.fatGrams*b.servings*item.servingsEaten)
      .reduce(function (c, d) {
        return c + d
      }, 0),
    id: item._id,
    mealInfo: meals.filter((a) => a._id === item.meal._id)[0].foodList.map((e) => ({
      foodId: e.food._id,
      foodName: e.food.name,
      servings: e.servings*item.servingsEaten*e.food.servingSize,
      servingSizeUnit: e.food.servingSizeUnit,
      calories: e.food.calories*e.servings*item.servingsEaten
    }))
  }))

  const numberOfCalories = lunchItems
    .map((a) => a.calories)
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const proteinTotal = lunchItems
    .map((a) => a.protein)
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const carbsTotal = lunchItems
    .map((a) => a.carbs)
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const fatTotal = lunchItems
    .map((a) => a.fat)
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const [dateCheck, setDateCheck] = useState(dateSearchInput)
  const [entryInfo, setEntryInfo] = useState({})
  const [entryModal, setEntryModal] = useState(false)
  const [addMealModal, setAddMealModal] = useState(false)
  const [mealDropdown, setMealDropdown] = useState(
    lunchItems
      .filter((item) => item.isMeal)
      .map((meal) => ({
        id: meal.id,
        dropdown: false,
        mealInfo: meal.mealInfo
    }))
  )

  const handleGetEntryInfo = (e) => {
    setEntryModal(true)
    let meal = mealEntries.filter((a) => a._id === e.currentTarget.id)[0]
    let food = foods.filter((c) => c._id === meal.food._id)[0]
    setEntryInfo({
      id: e.currentTarget.id,
      foodName: food.name,
      brand: food.brand,
      servingsEaten: meal.servingsEaten,
      entryDay: meal.mealDay,
      entryDatetime: meal.mealDatetime.split('T')[0],
      category: meal.category,
      calories: food.calories,
      protein: food.proteinGrams,
      carbs: food.carbsGrams,
      fat: food.fatGrams,
      homemade: false,
      ingredients: {},
    })
  }

  const handleAddMealEntry = (e) => {
    setAddMealModal(true)
  }

  const handleDropDownMealInfo = (e) => {
    setMealDropdown(
      mealDropdown
        .map((meal) => (meal.id === e.currentTarget.id ? 
          {
            ...meal,
            dropdown: !meal.dropdown
          } : meal)))
  }

  return (
    <div style={{ width: '100%', margin: '5px 0 0 0' }}>
      <MealEntryModal
        addMealModal={addMealModal}
        setAddMealModal={setAddMealModal}
        currentUserId={currentUserId}
        categoryChoice={'Lunch'}
        dateSearchInput={dateSearchInput}
        dayOfWeek={dayOfWeek}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
      <Card style={{ backgroundColor: '#c0f9dd' }}>
        <Card.Title className='text-center'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h1 style={{ fontSize: '20px', marginLeft: '10%', width: '80%' }}>
              Lunch - Calories: {numberOfCalories}
            </h1>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', color: '#85b1ff' }}
              onClick={handleAddMealEntry}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </Card.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceAround',
            margin: '0 0 15px 0',
          }}
        >
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1%',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Protein
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {proteinTotal}g
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Carbs
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {carbsTotal}g
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Fat
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {fatTotal}g
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
        <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
          {lunchItems.length > 0 &&
            lunchItems.map((a, index) => !a.isMeal ? (
              <div
                key={a.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  backgroundColor: '#c0e2f9',
                    margin: '2px 0'
                }}
              >
                {entryModal && (
                  <div>
                    <InfoModals
                      index={a.id}
                      mealEntries={mealEntries}
                      foods={foods}
                      entryModal={entryModal}
                      setEntryModal={setEntryModal}
                      entryInfo={entryInfo}
                      submitted={submitted}
                      setSubmitted={setSubmitted}
                    />
                  </div>
                )}
                <Button
                  className='border-0 fa-light'
                  variant='outline-dark'
                  style={{ width: '10%' }}
                  id={a.id}
                  onClick={handleGetEntryInfo}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <li
                    key={index}
                    style={{
                      height: '32px',
                      width: '68%',
                      margin: '0 11% 0 1%',
                      fontSize: '14px',
                      paddingTop: '8px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <p style={{ width: '60%' }}>
                      {a.text[0].length < 20
                        ? a.text[0]
                        : `${a.text[0].slice(0, 20)}...`}
                    </p>
                    <p>{`${Math.round(a.calories)} calories`}</p>
                  </li>
                <Button
                  className='border-0'
                  variant='outline-dark'
                  style={{ width: '10%' }}
                  id={a.id}
                  onClick={deleteConfirmation}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            ) : (
              <div key={a.id} style = {{ backgroundColor: '#c0e2f9' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    margin: '2px 0'
                  }}
                >
                  {entryModal && (
                    <div>
                      <InfoModals
                        index={a.id}
                        mealEntries={mealEntries}
                        foods={foods}
                        entryModal={entryModal}
                        setEntryModal={setEntryModal}
                        entryInfo={entryInfo}
                        submitted={submitted}
                        setSubmitted={setSubmitted}
                      />
                    </div>
                  )}
                  <Button
                    className='border-0 fa-light'
                    variant='outline-dark'
                    style={{ width: '10%' }}
                    id={a.id}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                  <li
                    key={index}
                    style={{
                      height: '32px',
                      width: '68%',
                      margin: '0 1%',
                      fontSize: '14px',
                      paddingTop: '8px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <p style={{ width: '60%' }}>
                      {a.text[0].length < 20
                        ? a.text[0]
                        : `${a.text[0].slice(0, 20)}...`}
                    </p>
                    <p>{`${Math.round(a.calories)} calories`}</p>
                  </li>
                  <Button
                    className='border-0'
                    variant='outline-dark'
                    style={{ width: '10%' }}
                    id={a.id}
                    onClick={handleDropDownMealInfo}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </Button>
                  <Button
                    className='border-0'
                    variant='outline-dark'
                    style={{ width: '10%' }}
                    id={a.id}
                    onClick={deleteConfirmation}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </div>
                {mealDropdown.length > 0 && mealDropdown.filter((meal) => meal.id === a.id)[0].dropdown &&
                  (mealDropdown
                    .filter((meal) => meal.id === a.id && meal.dropdown)[0].mealInfo
                    .map((food) => (
                      <div key={food.foodId} style = {{ height: '20px', marginLeft: '3%', fontSize: '12px', display: 'flex', 'flexDirection': 'row' }}>
                        <h1 style = {{ fontSize: '10px', width: '65%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${food.servings.toFixed(1)} ${food.servingSizeUnit} ${food.foodName}:`}</h1>
                        <h1 style = {{ fontSize: '10px', marginLeft: '5%' }}>{`${food.calories.toFixed(0)} Calories`}</h1>
                      </div>
                    )))
                  }
              </div>
            ))}
        </ul>
      </Card>
    </div>
  )
}

export default Lunch
