export function WaterEmpty() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='32px'
      viewBox='0 -960 960 960'
      width='32px'
      fill='#e8eaed'
    >
      <path d='M279-80q-31 0-53.5-20T200-151l-80-729h720l-80 729q-3 31-25.5 51T681-80H279Zm-8-160 9 80h400l9-80H271Zm-8-80h435l52-480H210l53 480Zm8 160h418-418Z' />
    </svg>
  )
}

export function WaterFull() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='32px'
      viewBox='0 -960 960 960'
      width='32px'
      fill='#75FBFD'
    >
      <path d='M444-600q-55 0-108 15.5T238-538l42 378h400l44-400h-28q-38 0-69-5.5T542-587q-23-7-48-10t-50-3Zm-216-25q51-27 105.5-41T445-680q30 0 59.5 4t58.5 12q50 14 76.5 19t56.5 5h37l17-160H210l18 175Zm51 545q-31 0-53.5-20T200-151l-80-729h720l-80 729q-3 31-25.5 51T681-80H279Zm165-80h236-400 164Z' />
    </svg>
  )
}
