import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext.js'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import Dropdowns from './postExercisesComponents/Dropdowns'
import AppNavbar from './AppNavbar'

import { apiGetCall, apiPostCall } from '../helpers/functions/proxyServer.js'
import { toTitleCase } from '../helpers/functions/regularFunctions.js'

const PostExercises = () => {
  function resolveAfter1Second(x) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(x)
      }, 100)
    })
  }

  async function doReset() {
    const x = await resolveAfter1Second(10)
    setUpdateNumbers(!updateNumbers)
    return x
  }

  async function doPostReset() {
    const x = await resolveAfter1Second(10)
    setUpdateNumbers(!updateNumbers)
    setExerciseChoice('')
    setMuscleGroupChoice('')
    setSuccessModal(true)
    return x
  }

  const deleteConfirmation = (e) => {
    if (
      exerciseEntries.filter((a) => a.exercise._id === e.currentTarget.id)
        .length > 0
    ) {
      setDeleteExerciseId(-1)
    } else {
      setDeleteExerciseId(e.currentTarget.id)
    }
    setDeleteConfirmModal(true)
  }

  const navigate = useNavigate()

  const goToExerciseEntries = (e) => {
    setSuccessModal(false)
    navigate('/')
  }

  const goToSummaries = (e) => {
    setSuccessModal(false)
    navigate('/')
  }

  function LaunchSuccessModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ textAlign: 'center' }}
          >
            You Successfully Submitted Your Exercise!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{
                width: '32%',
                height: '50px',
                marginLeft: '1%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={props.onHide}
            >
              Add Another Exercise
            </Button>
            <Button
              style={{
                width: '32%',
                height: '50px',
                marginLeft: '1%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={goToExerciseEntries}
            >
              Add Workout
            </Button>
            <Button
              style={{
                width: '32%',
                height: '50px',
                margin: '0 1%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={goToSummaries}
            >
              See Summaries
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function LaunchDeleteConfirm(props, e) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ textAlign: 'center' }}
          >
            {deleteExerciseId === -1
              ? 'There are exercise entries in the database using this exercise. You can no longer delete it'
              : 'Are you sure you want to delete?'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0' }}>
          {deleteExerciseId !== -1 && (
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Button
                style={{
                  width: '47%',
                  height: '50px',
                  marginLeft: '2%',
                  fontSize: '14px',
                }}
                variant='outline-dark'
                onClick={handleRemoveEntryFromDatabase}
              >
                Yes
              </Button>
              <Button
                style={{
                  width: '47%',
                  height: '50px',
                  marginLeft: '2%',
                  fontSize: '14px',
                }}
                variant='outline-dark'
                onClick={props.onHide}
              >
                No
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }

  const handleRemoveEntryFromDatabase = (e) => {
    apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/delete-exercises/',
      { ids: [deleteExerciseId] }
    )
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        console.log(error)
      })
    if (deleteExerciseId !== -1) {
      console.log('Tried to delete id: ', deleteExerciseId)
    }

    doReset()
    setDeleteConfirmModal(false)
  }

  const { currentUserId } = useAuth()
  const [cardioButton, setCardioButton] = useState('outline-dark')
  const [weightLiftingButton, setWeightLiftingButton] = useState('outline-dark')
  const [updateNumbers, setUpdateNumbers] = useState(true)
  const [exercises, setExercises] = useState([])
  const [exerciseEntries, setExerciseEntries] = useState([])
  const [muscleGroupChoice, setMuscleGroupChoice] = useState('')
  const [workoutType, setWorkoutType] = useState(2)
  const [category, setCategory] = useState('')
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [deleteExerciseId, setDeleteExerciseId] = useState(-1)

  const [exerciseChoice, setExerciseChoice] = useState('')

  const chooseExercise = (e) => {
    setExerciseChoice(e.target.textContent)
  }

  const chooseMuscleGroup = (e) => {
    setMuscleGroupChoice(e.target.textContent)
  }

  useEffect(() => {
    apiGetCall('https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercises')
      .then((response) => {
        setExercises(response.data)
      })
      .catch((error) => {
        console.error(error)
      })

    apiGetCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercise-entries'
    )
      .then((response) => {
        setExerciseEntries(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [exerciseChoice, updateNumbers, exerciseEntries, exercises])

  const handlePostExerciseSubmit = () => {
    const body = {
      createdByUser: currentUserId,
      name: exerciseChoice,
      category: category,
      muscleGroup: muscleGroupChoice,
    }

    apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercise',
      body
    )
      .then(function (response) {
        console.log(response)
        doPostReset()
        setExerciseChoice('')
        setMuscleGroupChoice('')
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleCardioChoice = (e) => {
    setWorkoutType(0)
    setCardioButton('dark')
    setWeightLiftingButton('outline-dark')
    setCategory('cardio')
    setMuscleGroupChoice('cardio')
  }

  const handleWeightLiftingChoice = (e) => {
    setWorkoutType(1)
    setCardioButton('outline-dark')
    setWeightLiftingButton('dark')
    setCategory('weight lifting')
    setMuscleGroupChoice('')
  }

  return (
    <div>
      <AppNavbar />
      <LaunchDeleteConfirm
        show={deleteConfirmModal}
        onHide={() => setDeleteConfirmModal(false)}
      />
      <LaunchSuccessModal
        show={successModal}
        onHide={() => setSuccessModal(false)}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '450px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '90%',
              margin: '0 5%',
            }}
          >
            <Button
              style={{ margin: '0 10px', fontSize: '12px', width: '45%' }}
              variant={cardioButton}
              onClick={handleCardioChoice}
            >
              Add Cardio Exercise
            </Button>
            <Button
              style={{ margin: '0 10px 0 5%', fontSize: '12px', width: '50%' }}
              variant={weightLiftingButton}
              onClick={handleWeightLiftingChoice}
            >
              Add Weight Lifting Exercise
            </Button>
          </div>
          <Dropdowns
            exercises={exercises}
            exerciseChoice={exerciseChoice}
            setExerciseChoice={setExerciseChoice}
            chooseExercise={chooseExercise}
            chooseMuscleGroup={chooseMuscleGroup}
            muscleGroupChoice={muscleGroupChoice}
            setMuscleGroupChoice={setMuscleGroupChoice}
            workoutType={workoutType}
          />
          <div>
            <Button
              variant='outline-dark'
              style={{ width: '50%', margin: '25px 25%' }}
              onClick={handlePostExerciseSubmit}
            >
              Submit Exercise
            </Button>
          </div>
          <div style={{ width: '100%' }}>
            <h1
              style={{
                fontSize: '12px',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              Exercises Created By You Will Appear Below!
            </h1>
            <ul
              style={{
                listStyleType: 'none',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                maxHeight: '450px',
                overflowY: 'scroll',
                margin: '0',
              }}
            >
              {exercises
                .filter((b) => b.createdByUser._id === currentUserId)
                .sort(function (a, b) {
                  const nameA = a.muscleGroup.toUpperCase()
                  const nameB = b.muscleGroup.toUpperCase()
                  if (nameA < nameB) {
                    return -1
                  } else {
                    return 1
                  }
                })
                .map((a, index) => (
                  <li
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '2px 2%',
                      width: '96%',
                      height: '40px',
                    }}
                  >
                    <Card
                      className='border-0'
                      style={{
                        width: '40%',
                        margin: '10px 0 10px 2%',
                        fontSize: '12px',
                        textAlign: 'center',
                        height: '20px',
                      }}
                    >
                      {toTitleCase(a.name.toLowerCase())}
                    </Card>
                    <Card
                      className='border-0'
                      style={{
                        width: '25%',
                        margin: '10px 0 10px 10%',
                        fontSize: '12px',
                        height: '20px',
                      }}
                    >
                      {a.muscleGroup}
                    </Card>
                    <Button
                      className='border-0'
                      variant='outline-dark'
                      style={{ width: '10%', marginLeft: '2%' }}
                      id={a._id}
                      onClick={deleteConfirmation}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostExercises
