import React, { useState, useEffect } from 'react'
import moment from 'moment'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'

import { useAuth } from '../contexts/AuthContext.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons'

import AppNavbar from './AppNavbar.js'
import Meals from './SummaryComponents/Meals.js'
import Exercises from './SummaryComponents/Exercises.js'
import Users from './SummaryComponents/Users.js'
import DailyTotals from './SummaryComponents/DailyTotals.js'
import Hydration from './SummaryComponents/Hydration.js'

import { apiGetCall, apiPostCall } from '../helpers/functions/proxyServer.js'
import { units, days } from '../helpers/objects.js'
import {
  subtractHours,
  resolveAfter1Second,
} from '../helpers/functions/regularFunctions.js'

const Summary = () => {
  async function doReset() {
    const x = await resolveAfter1Second(10)
    setSubmitted(!submitted)
    return x
  }

  const chooseDeleteMealEntryId = (e) => {
    setDeleteExerciseOrMeal('meal')
    setDeleteMealEntryId(e)
    setDeleteConfirmModal(true)
  }

  const chooseDeleteExerciseEntryId = (e) => {
    setDeleteExerciseOrMeal('exercise')
    setDeleteExerciseEntryId(e)
    setDeleteConfirmModal(true)
  }

  function LaunchDeleteConfirm(props, e) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Are you sure you want to delete?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant='outline-dark'
            onClick={handleRemoveEntryFromDatabase}
            style={{
              width: '47%',
              height: '50px',
              marginLeft: '2%',
              fontSize: '14px',
            }}
          >
            Yes
          </Button>
          <Button
            variant='outline-dark'
            onClick={props.onHide}
            style={{
              width: '47%',
              height: '50px',
              marginLeft: '2%',
              fontSize: '14px',
            }}
          >
            No
          </Button>
        </Modal.Body>
      </Modal>
    )
  }

  async function handleRemoveEntryFromDatabase() {
    if (deleteExerciseOrMeal === 'meal') {
      let res = await apiPostCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/delete-meal-entries/',
        { ids: [deleteMealEntryId] }
      )
      setDeleteConfirmModal(false)
      doReset()
    } else if (deleteExerciseOrMeal === 'exercise') {
      let res = await apiPostCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/delete-exercise-entries',
        { ids: [deleteExerciseEntryId] }
      )
      setDeleteConfirmModal(false)
      doReset()
    }
  }

  const [loading, setLoading] = useState(true)

  const timezoneOffset = new Date().getTimezoneOffset() / 60
  const [datetimeEntry, setDatetimeEntry] = useState(subtractHours(new Date(), timezoneOffset))
  const [dayOfWeek, setDayOfWeek] = useState(days[datetimeEntry.getDay()])
  const [dateProp, setDateProp] = useState(datetimeEntry.toJSON().slice(0, 10))

  const {
    currentUserFirstName,
    currentUserLastName,
    currentUserId,
    currentUserHydration,
  } = useAuth()
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [meals, setMeals] = useState([])
  const [mealEntries, setMealEntries] = useState([])
  const [user, setUser] = useState()
  const [foods, setFoods] = useState([])
  const [exercises, setExercises] = useState([])
  const [exerciseEntries, setExerciseEntries] = useState([])
  const [deleteMealEntryId, setDeleteMealEntryId] = useState(-1)
  const [deleteExerciseEntryId, setDeleteExerciseEntryId] = useState(-1)
  const [deleteExerciseOrMeal, setDeleteExerciseOrMeal] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [noMeals, setNoMeals] = useState(false)
  const [noExercises, setNoExercises] = useState(false)
  const [hydrationArray, setHydrationArray] = useState()

  async function postNewHydrationArray(newObj) {
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/user/',
      newObj
    )
  }

  useEffect(() => {
    setLoading(true)

    async function getAllData() {
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercises'
      )
        .then((response) => {
          setExercises(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/exercise-entries'
      )
        .then((response) => {
          setExerciseEntries(
            response.data.filter((a) => a.user._id === currentUserId)
          )
        })
        .catch((error) => {
          console.error(error)
        })
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meals/'
      )
        .then((response) => {
          setMeals(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal-entries/'
      )
        .then((response) => {
          setMealEntries(
            response.data.filter((a) => a.user._id === currentUserId)
          )
        })
        .catch((error) => {
          console.error(error)
        })
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/foods/'
      )
        .then((response) => {
          setFoods(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/users/'
      )
        .then((response) => {
          let newUser = response.data.filter((a) => a._id === currentUserId)[0]
          setUser(newUser)
          if (
            newUser.hydration.filter((a) => String(a.date).includes(dateProp))
              .length === 0
          ) {
            let addHydration = [
              ...newUser.hydration,
              { date: dateProp, cupsOfWaterDrank: 0 },
            ]
            setHydrationArray(addHydration)
            postNewHydrationArray({
              ...newUser,
              id: currentUserId,
              hydration: addHydration,
            })
          } else {
            setHydrationArray(
              newUser ? newUser.hydration : currentUserHydration
            )
          }
        })
        .then(() => {
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    getAllData()
  }, [submitted, currentUserId, dateProp])

  async function handlePreviousDayClick() {
    let a = moment(datetimeEntry).subtract(1, 'days')._d
    setLoading(true)
    if (
      user.hydration.filter((b) =>
        String(b.date).includes(a.toJSON().slice(0, 10))
      ).length === 0
    ) {
      let newObj = {
        ...user,
        id: currentUserId,
        hydration: [
          ...hydrationArray,
          { date: a.toJSON().slice(0, 10), cupsOfWaterDrank: 0 },
        ],
      }
      await postNewHydrationArray(newObj)
        .then(() => {
          setDatetimeEntry(a)
          setDayOfWeek(days[a.getDay()])
          setDateProp(a.toJSON().slice(0, 10))
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setDatetimeEntry(a)
      setDayOfWeek(days[a.getDay()])
      setDateProp(a.toJSON().slice(0, 10))
    }
  }

  async function handleNextDayClick() {
    let a = moment(datetimeEntry).add(1, 'days')._d
    setLoading(true)
    if (
      user.hydration.filter((b) =>
        String(b.date).includes(a.toJSON().slice(0, 10))
      ).length === 0
    ) {
      let newObj = {
        ...user,
        id: currentUserId,
        hydration: [
          ...hydrationArray,
          { date: a.toJSON().slice(0, 10), cupsOfWaterDrank: 0 },
        ],
      }
      await postNewHydrationArray(newObj)
        .then(() => {
          setDatetimeEntry(a)
          setDayOfWeek(days[a.getDay()])
          setDateProp(a.toJSON().slice(0, 10))
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setDatetimeEntry(a)
      setDayOfWeek(days[a.getDay()])
      setDateProp(a.toJSON().slice(0, 10))
    }
  }

  useEffect(() => {
    if (
      mealEntries.filter((a) => String(a.mealDatetime).includes(dateProp))
        .length === 0
    ) {
      setNoMeals(true)
    } else {
      setNoMeals(false)
    }

    if (
      exerciseEntries.filter((a) =>
        String(a.exerciseDatetime).includes(dateProp)
      ).length === 0
    ) {
      setNoExercises(true)
    } else {
      setNoExercises(false)
    }
  }, [datetimeEntry, dateProp, exerciseEntries, mealEntries])

  return (
    <div>
      <AppNavbar />
      {loading ? (
        <div className='page-loading'>
          <FontAwesomeIcon className='fa-spin fa-5x' icon={faSpinner} />
        </div>
      ) : (
        <div>
          <LaunchDeleteConfirm
            show={deleteConfirmModal}
            onHide={() => setDeleteConfirmModal(false)}
          />
          <div>
            <div>
              <h1 className='info-text'>{dayOfWeek}</h1>
              <div className='flex-row'>
                <Button
                  className='border-0'
                  variant='outline-dark'
                  onClick={handlePreviousDayClick}
                >
                  <FontAwesomeIcon icon={faCaretLeft} />
                </Button>
                <Card className='border-0'>
                  <Card.Body>{datetimeEntry.toJSON().slice(0, 10)}</Card.Body>
                </Card>
                <Button
                  className='border-0'
                  variant='outline-dark'
                  onClick={handleNextDayClick}
                >
                  <FontAwesomeIcon icon={faCaretRight} />
                </Button>
              </div>
            </div>
            {noMeals && noExercises && (
              <h1 className='info-text'>No Results For That Day!</h1>
            )}
            <Users
              dateSearchInput={dateProp}
              mealEntries={mealEntries}
              exerciseEntries={exerciseEntries}
              foods={foods}
              meals={meals}
              currentUserFirstName={currentUserFirstName}
              currentUserLastName={currentUserLastName}
            />
            <DailyTotals
              mealEntries={mealEntries}
              foods={foods}
              exercises={exercises}
              exerciseEntries={exerciseEntries}
              dateSearchInput={dateProp}
              user={user}
              currentUserId={currentUserId}
              datetimeEntry={datetimeEntry}
            />
            <Hydration
              dateSearchInput={dateProp}
              user={user}
              currentUserId={currentUserId}
              hydrationArray={hydrationArray}
              setHydrationArray={setHydrationArray}
            />
            <Meals
              meals={meals}
              mealEntries={mealEntries}
              foods={foods}
              units={units}
              dateSearchInput={dateProp}
              datetimeEntry={datetimeEntry}
              dayOfWeek={dayOfWeek}
              chooseDeleteMealEntryId={chooseDeleteMealEntryId}
              submitted={submitted}
              setSubmitted={setSubmitted}
              currentUserId={currentUserId}
            />
            <Exercises
              dateSearchInput={dateProp}
              exercises={exercises}
              exerciseEntries={exerciseEntries}
              chooseDeleteExerciseEntryId={chooseDeleteExerciseEntryId}
              dayOfWeek={dayOfWeek}
              submitted={submitted}
              setSubmitted={setSubmitted}
              currentUserId={currentUserId}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Summary
