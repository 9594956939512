import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../contexts/AuthContext'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faEdit,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons'

import AppNavbar from './AppNavbar'

import { apiGetCall } from '../helpers/functions/proxyServer.js'

const Profile = () => {
  const [loading, setLoading] = useState(false)
  const { currentUserId, currentUser, currentUserFirstName } = useAuth()
  const [userProfile, setUserProfile] = useState([])
  const [userProfileChange, setUserProfileChange] = useState(false)
  const [stateChange, setStateChange] = useState(false)

  function userProfileArray(res) {
    setUserProfile([
      {
        id: 1,
        value: res.heightInches,
        varName: 'heightInches',
        header: 'Height (Inches):',
        type: 'noEdit',
      },
      {
        id: 2,
        value: res.weightPounds,
        varName: 'weightPounds',
        header: 'Current Weight (Pounds):',
        type: 'noEdit',
      },
      {
        id: 3,
        value: res.goalWeightPounds,
        varName: res.goalWeightPounds,
        header: 'Goal Weight (Pounds):',
        type: 'noEdit',
      },
      {
        id: 4,
        value: res.goalDailyExerciseDuration,
        varName: 'goalDailyExerciseDuration',
        header: 'Exercise Goal (Minutes Per Day):',
        type: 'noEdit',
      },
      {
        id: 5,
        value: res.goalDailyCaloriesBurned,
        varName: 'goalDailyCaloriesBurned',
        header: 'Calories Burned Goal (Daily):',
        type: 'noEdit',
      },
      {
        id: 6,
        value: res.goalDailyCaloriesEaten,
        varName: 'goalDailyCaloriesEaten',
        header: 'Calories Eaten Goal (Daily):',
        type: 'noEdit',
      },
      {
        id: 7,
        value: res.goalDailyProteinGrams,
        varName: 'goalDailyProteinGrams',
        header: 'Daily Protein Goal (grams):',
        type: 'noEdit',
      },
      {
        id: 8,
        value: res.goalDailyCarbGrams,
        varName: 'goalDailyCarbGrams',
        header: 'Daily Carbs Goal (grams):',
        type: 'noEdit',
      },
      {
        id: 9,
        value: res.goalDailyFatGrams,
        varName: 'goalDailyFatGrams',
        header: 'Daily Fat Goal (grams):',
        type: 'noEdit',
      },
      {
        id: 10,
        value: res.goalDailyWaterCups,
        varName: 'goalDailyWaterCups',
        header: 'Daily Water Goal (Cups):',
        type: 'noEdit',
      },
    ])
  }

  useEffect(() => {
    setLoading(true)

    async function getAllData() {
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/users/'
      )
        .then((response) => {
          userProfileArray(
            response.data.filter((a) => a._id === currentUserId)[0]
          )
        })
        .then(() => {
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    getAllData()
  }, [userProfileChange, currentUserId])

  const handleEditProfile = (a) => (e) => {
    let obj = userProfile
    obj[a - 1].type = 'edit'
    setUserProfile(obj)
    setStateChange(!stateChange)
  }

  const handleChangeProfile = (a) => (e) => {
    let obj = userProfile
    obj[a - 1].value = e.target.value
    setUserProfile(obj)
    setStateChange(!stateChange)
  }

  async function handleSaveProfile() {
    const obj = {
      id: currentUserId,
      heightInches: Number(userProfile[0].value),
      weightPounds: Number(userProfile[1].value),
      goalWeightPounds: Number(userProfile[2].value),
      goalDailyCaloriesEaten: Number(userProfile[5].value),
      goalDailyProteinGrams: Number(userProfile[6].value),
      goalDailyCarbGrams: Number(userProfile[7].value),
      goalDailyFatGrams: Number(userProfile[8].value),
      goalDailyCaloriesBurned: Number(userProfile[4].value),
      goalDailyExerciseDuration: Number(userProfile[3].value),
      goalDailyWaterCups: Number(userProfile[9].value),
    }

    await axios
      .post('https://fisher-fitness-91bf435fdc2b.herokuapp.com/user/', obj)
      .then(function (response) {
        console.log(response)
        setUserProfileChange(!userProfileChange)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div>
      <AppNavbar />
      {loading ? (
        <div
          className='fa-5x'
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '100px 0 0 0',
          }}
        >
          <FontAwesomeIcon className='fa-spin' icon={faSpinner} />
        </div>
      ) : (
        <div>
          <Card className='border-0'>
            <Card.Body>
              {currentUser && (
                <Card.Title className='text-center'>
                  Hello, {currentUserFirstName}
                </Card.Title>
              )}
            </Card.Body>
          </Card>
          <ul style={{ listStyleType: 'none', padding: '0 10px' }}>
            {userProfile.map((a) => (
              <li
                key={a.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '40px',
                  width: '80%',
                  alignItems: 'center',
                  margin: '2px 10%',
                }}
              >
                {a.type === 'noEdit' ? (
                  <>
                    <h1
                      style={{
                        fontSize: '14px',
                        width: '65%',
                        margin: '0 0 0 5px',
                        textAlign: 'right',
                      }}
                    >
                      {a.header}
                    </h1>
                    <h2
                      style={{
                        fontSize: '14px',
                        width: '15%',
                        margin: '0 0 0 5%',
                        textAlign: 'left',
                      }}
                    >
                      {a.value}
                    </h2>
                    <Button
                      className='border-0'
                      variant='outline-dark'
                      style={{ width: '10%', marginLeft: '1%' }}
                      id={a.id}
                      onClick={handleEditProfile(a.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </>
                ) : a.type === 'edit' ? (
                  <>
                    <h1
                      style={{
                        fontSize: '14px',
                        width: '65%',
                        margin: '0 0 0 5px',
                        textAlign: 'right',
                      }}
                    >
                      {a.header}
                    </h1>
                    <InputGroup
                      style={{
                        fontSize: '14px',
                        width: '15%',
                        margin: '0 0 0 5%',
                        textAlign: 'left',
                      }}
                    >
                      <Form.Control
                        aria-label='Food'
                        placeholder='Type Goal Here...'
                        value={a.value}
                        onChange={handleChangeProfile(a.id)}
                      />
                    </InputGroup>
                    <Button
                      className='border-0'
                      variant='outline-dark'
                      style={{ width: '10%', marginLeft: '1%' }}
                      onClick={handleEditProfile(a.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </>
                ) : null}
              </li>
            ))}
          </ul>
          <div style={{ width: '100%' }}>
            <Button
              style={{
                width: '20%',
                height: '25%',
                margin: '10px 40%',
                border: '0',
              }}
              variant='outline-dark'
              onClick={handleSaveProfile}
            >
              <FontAwesomeIcon className='fa-3x' icon={faFloppyDisk} />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profile
