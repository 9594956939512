// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.svg-edit,
.svg-delete {
  height: 2em;
  width: 2em;
}

.bg-pbColor {
  background-color: #42d4ff !important;
}

.page-loading {
  text-align: center;
  margin: '100px 0 0 0';
}

.info-text {
  text-align: center;
  font-size: 14px;
  margin: 0 !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".dropdown-menu {\n  max-height: 300px;\n  overflow-y: scroll;\n}\n\n.svg-edit,\n.svg-delete {\n  height: 2em;\n  width: 2em;\n}\n\n.bg-pbColor {\n  background-color: #42d4ff !important;\n}\n\n.page-loading {\n  text-align: center;\n  margin: '100px 0 0 0';\n}\n\n.info-text {\n  text-align: center;\n  font-size: 14px;\n  margin: 0 !important;\n}\n\n.flex-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
