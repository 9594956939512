export const units = [
  'ounces',
  'oz',
  'ounce',
  'ml',
  'mls',
  'milliliters',
  'g',
  'gram',
  'grams',
  'lb',
  'lbs',
  'pounds',
  'pound',
]
export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
