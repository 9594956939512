import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import { WaterEmpty, WaterFull } from '../../helpers/images'
import { apiPostCall } from '../../helpers/functions/proxyServer'

const Hydration = ({
  dateSearchInput,
  user,
  hydrationArray,
  setHydrationArray,
}) => {
  const waterGoal = user && user.goalDailyWaterCups

  const row1 = waterGoal > 10 ? Math.ceil(waterGoal / 2) : waterGoal
  const row2 = waterGoal > 10 ? Math.floor(waterGoal / 2) : 0

  const hydration = hydrationArray.filter((a) =>
    String(a.date).includes(dateSearchInput)
  )[0].cupsOfWaterDrank

  const [cupsArray, setCupsArray] = useState(
    [...Array(waterGoal).keys()].map((obj) => {
      if (obj < hydration) {
        return { id: obj, fullStatus: true }
      } else {
        return {
          id: obj,
          fullStatus: false,
        }
      }
    })
  )

  async function handleChangeCup(e) {
    let newHydrationArray = []
    const newCupsAmount = Number(e.currentTarget.id)

    if (
      newCupsAmount === 0 &&
      cupsArray.filter((a) => a.fullStatus).length === 1
    ) {
      newHydrationArray = hydrationArray.map((obj) => {
        if (String(obj.date).includes(dateSearchInput)) {
          return {
            ...obj,
            cupsOfWaterDrank: 0,
          }
        } else {
          return obj
        }
      })

      setCupsArray(
        [...Array(waterGoal).keys()].map((obj) => {
          return { id: obj, fullStatus: false }
        })
      )
    } else {
      newHydrationArray = hydrationArray.map((obj) => {
        if (String(obj.date).includes(dateSearchInput)) {
          return {
            ...obj,
            cupsOfWaterDrank: newCupsAmount + 1,
          }
        } else {
          return obj
        }
      })

      setCupsArray(
        [...Array(waterGoal).keys()].map((obj) => {
          if (obj <= newCupsAmount) {
            return { id: obj, fullStatus: true }
          } else {
            return {
              id: obj,
              fullStatus: false,
            }
          }
        })
      )
    }
    setHydrationArray(newHydrationArray)

    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/user/',
      {
        ...user,
        id: user._id,
        hydration: newHydrationArray,
      }
    )
  }

  return (
    <div style={{ width: '90%', margin: '5px 5%' }}>
      <Card style={{ backgroundColor: '#ffffff' }}>
        <Card.Title className='text-center' style={{ fontSize: '18px' }}>
          Water Goal: {cupsArray.filter((a) => a.fullStatus).length}/{waterGoal}{' '}
          cups
        </Card.Title>
        <ul
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80%',
            margin: '0 10%',
            listStyleType: 'none',
            padding: '0',
          }}
        >
          {cupsArray
            .filter((a) => a.id < row1)
            .map((cup) => (
              <li
                key={cup.id}
                style={{
                  width: `${100 / row1}%`,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  className='border-0 fa-light'
                  variant='outline'
                  style={{ padding: '0' }}
                  onClick={handleChangeCup}
                  id={cup.id}
                >
                  {cup.fullStatus ? <WaterFull /> : <WaterEmpty />}
                </Button>
              </li>
            ))}
        </ul>
        <ul
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80%',
            margin: '0 10%',
            listStyleType: 'none',
            padding: '0',
          }}
        >
          {cupsArray
            .filter((a) => a.id >= row1)
            .map((cup) => (
              <li
                key={cup.id}
                style={{
                  width: `${100 / row2}%`,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  className='border-0 fa-light'
                  variant='outline'
                  style={{ padding: '0' }}
                  onClick={handleChangeCup}
                  id={cup.id}
                >
                  {cup.fullStatus ? <WaterFull /> : <WaterEmpty />}
                </Button>
              </li>
            ))}
        </ul>
      </Card>
    </div>
  )
}

export default Hydration
