import React from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'
import { useAuth } from '../contexts/AuthContext.js'

const AppNavbar = () => {
  const {
    currentUserEmail,
    currentUserFirstName,
    currentUserLastName,
    logout,
  } = useAuth()
  const navigate = useNavigate()

  async function handleLogout(e) {
    try {
      await logout()
      navigate('/login')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Navbar key={false} expand={false} className='bg-body-tertiary mb-3'>
        <Navbar.Toggle
          className='border-0 m-1'
          aria-controls={`offcanvasNavbar-expand-${false}`}
        />
        <Navbar.Brand
          style={{
            position: 'absolute',
            textAlign: 'left',
            margin: '0 0 0 75px',
          }}
        >
          Fisher Fitness
        </Navbar.Brand>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${false}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
          placement='start'
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
              <ul
                style={{
                  listStyleType: 'none',
                  textAlign: 'left',
                  paddingLeft: '0',
                  margin: '5px 0',
                }}
              >
                <li>
                  {currentUserFirstName} {currentUserLastName}
                </li>
                <li>{currentUserEmail}</li>
              </ul>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className='justify-content-end flex-grow-1 pe-3'>
              <Nav.Link href='/'>See Summaries</Nav.Link>
              <Nav.Link href='/post/exercises'>Add Exercise</Nav.Link>
              <Nav.Link href='/profile'>Profile</Nav.Link>
              <Button
                variant='link'
                type='submit'
                onClick={handleLogout}
                style={{
                  position: 'absolute',
                  width: '95%',
                  bottom: '0',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Log Out
              </Button>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </div>
  )
}

export default AppNavbar
