import React from 'react'
import Breakfast from './MealTypes/Breakfast'
import Lunch from './MealTypes/Lunch'
import Dinner from './MealTypes/Dinner'
import Snacks from './MealTypes/Snacks'

const Meals = ({
  dateSearchInput,
  dayOfWeek,
  mealEntries,
  foods,
  units,
  chooseDeleteMealEntryId,
  homemadeFoods,
  ingredients,
  submitted,
  setSubmitted,
  currentUserId,
}) => {
  return (
    <div style={{ width: '90%', margin: '5px 5%' }}>
      <Breakfast
        foods={foods}
        homemadeFoods={homemadeFoods}
        mealEntries={mealEntries}
        units={units}
        dateSearchInput={dateSearchInput}
        dayOfWeek={dayOfWeek}
        chooseDeleteMealEntryId={chooseDeleteMealEntryId}
        submitted={submitted}
        setSubmitted={setSubmitted}
        currentUserId={currentUserId}
        ingredients={ingredients}
      />
      <Lunch
        foods={foods}
        homemadeFoods={homemadeFoods}
        mealEntries={mealEntries}
        units={units}
        dateSearchInput={dateSearchInput}
        dayOfWeek={dayOfWeek}
        chooseDeleteMealEntryId={chooseDeleteMealEntryId}
        submitted={submitted}
        setSubmitted={setSubmitted}
        currentUserId={currentUserId}
        ingredients={ingredients}
      />
      <Dinner
        foods={foods}
        homemadeFoods={homemadeFoods}
        mealEntries={mealEntries}
        units={units}
        dateSearchInput={dateSearchInput}
        dayOfWeek={dayOfWeek}
        chooseDeleteMealEntryId={chooseDeleteMealEntryId}
        submitted={submitted}
        setSubmitted={setSubmitted}
        currentUserId={currentUserId}
        ingredients={ingredients}
      />
      <Snacks
        foods={foods}
        homemadeFoods={homemadeFoods}
        mealEntries={mealEntries}
        units={units}
        dateSearchInput={dateSearchInput}
        dayOfWeek={dayOfWeek}
        chooseDeleteMealEntryId={chooseDeleteMealEntryId}
        submitted={submitted}
        setSubmitted={setSubmitted}
        currentUserId={currentUserId}
        ingredients={ingredients}
      />
    </div>
  )
}

export default Meals
