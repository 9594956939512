import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTrashAlt,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons'

import InfoModals from './modals/InfoModals'
import MealEntryModal from './modals/MealEntryModal'

const Lunch = ({
  foods,
  mealEntries,
  units,
  currentUserId,
  dateSearchInput,
  dayOfWeek,
  chooseDeleteMealEntryId,
  submitted,
  setSubmitted,
}) => {
  const deleteConfirmation = (e) => {
    chooseDeleteMealEntryId(e.currentTarget.id)
  }

  const lunchMeals = mealEntries.filter(
    (c) =>
      c.category === 'Lunch' && String(c.mealDatetime).includes(dateSearchInput)
  )

  const lunchItems = lunchMeals.map((item) => ({
    text: foods
      .filter((a) => a._id === item.food._id)
      .map(
        (n) =>
          (item.servingsEaten * n.servingSize).toFixed(1) +
          ' ' +
          (units.includes(n.servingSizeUnit.toLowerCase())
            ? n.servingSizeUnit.toLowerCase() + ' '
            : '') +
          n.name
      ),
    calories: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.calories)),
    protein: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.proteinGrams)),
    carbs: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.carbsGrams)),
    fat: foods
      .filter((a) => a._id === item.food._id)
      .map((n) => Number(item.servingsEaten * n.fatGrams)),
    id: item._id,
  }))

  const numberOfCalories = lunchItems
    .map((a) => a.calories[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const proteinTotal = lunchItems
    .map((a) => a.protein[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const carbsTotal = lunchItems
    .map((a) => a.carbs[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const fatTotal = lunchItems
    .map((a) => a.fat[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const [entryInfo, setEntryInfo] = useState({})

  const [entryModal, setEntryModal] = useState(false)
  const [addMealModal, setAddMealModal] = useState(false)

  const handleGetEntryInfo = (e) => {
    setEntryModal(true)
    let meal = mealEntries.filter((a) => a._id === e.currentTarget.id)[0]
    let food = foods.filter((c) => c._id === meal.food._id)[0]
    console.log(food)
    setEntryInfo({
      id: e.currentTarget.id,
      foodName: food.name,
      brand: food.brand,
      servingsEaten: meal.servingsEaten,
      entryDay: meal.mealDay,
      entryDatetime: meal.mealDatetime.split('T')[0],
      category: meal.category,
      calories: food.calories,
      protein: food.proteinGrams,
      carbs: food.carbsGrams,
      fat: food.fatGrams,
      homemade: false,
      ingredients: {},
    })
  }

  const handleAddMealEntry = (e) => {
    setAddMealModal(true)
  }

  return (
    <div style={{ width: '100%', margin: '5px 0' }}>
      <MealEntryModal
        addMealModal={addMealModal}
        setAddMealModal={setAddMealModal}
        currentUserId={currentUserId}
        categoryChoice={'Lunch'}
        dateSearchInput={dateSearchInput}
        dayOfWeek={dayOfWeek}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
      <Card style={{ backgroundColor: '#c0f9dd' }}>
        <Card.Title className='text-center'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h1 style={{ fontSize: '20px', marginLeft: '10%', width: '80%' }}>
              Lunch - Calories: {numberOfCalories}
            </h1>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', color: '#85b1ff' }}
              onClick={handleAddMealEntry}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </Card.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceAround',
            margin: '0 0 15px 0',
          }}
        >
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1%',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Protein
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {proteinTotal}g
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Carbs
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {carbsTotal}g
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '32%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Fat
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {fatTotal}g
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
        <ul style={{ listStyleType: 'none', padding: '0' }}>
          {lunchItems.length > 0 &&
            lunchItems.map((a, index) => (
              <div
                key={a.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {entryModal && (
                  <div>
                    <InfoModals
                      index={a.id}
                      mealEntries={mealEntries}
                      foods={foods}
                      entryModal={entryModal}
                      setEntryModal={setEntryModal}
                      entryInfo={entryInfo}
                      submitted={submitted}
                      setSubmitted={setSubmitted}
                    />
                  </div>
                )}
                <Button
                  className='border-0 fa-light'
                  variant='outline-dark'
                  style={{ width: '10%', color: '#85b1ff' }}
                  id={a.id}
                  onClick={handleGetEntryInfo}
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </Button>
                <li
                  key={index}
                  style={{
                    height: '32px',
                    width: '78%',
                    margin: '0 1%',
                    fontSize: '14px',
                    paddingTop: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <p style={{ width: '70%' }}>
                    {a.text[0].length < 25
                      ? a.text[0]
                      : `${a.text[0].slice(0, 25)}...`}
                  </p>
                  <p>{`${Math.round(a.calories[0])} calories`}</p>
                </li>
                <Button
                  className='border-0'
                  variant='outline-dark'
                  style={{ width: '10%' }}
                  id={a.id}
                  onClick={deleteConfirmation}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            ))}
        </ul>
      </Card>
    </div>
  )
}

export default Lunch
