import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError('')
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      navigate('/')
    } catch {
      setError('Failed To Log In')
    }

    setLoading(false)
  }

  return (
    <div>
      <Card style={{ margin: '25px 0' }}>
        <Card.Body>
          <h2 className='w-100 text-center'>Log In</h2>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' ref={emailRef} required />
            </Form.Group>
            <Form.Group id='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control type='password' ref={passwordRef} required />
            </Form.Group>
            <Button
              disabled={loading}
              style={{ margin: '20px 0 0 0', width: '100%' }}
              type='submit'
            >
              Log In
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className='basic-text'>
        Don't Have An Account Yet? <Link to='/signup'>Sign Up</Link>
      </div>
    </div>
  )
}

export default Login
