import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from 'react-bootstrap/Card'

const BrandDropdown = ({ foods, brandChoice, setBrandChoice, chooseBrand }) => {
  const startingBrandList = foods.map((a) => a.brand)

  const [updatedBrandList, setUpdatedBrandList] = useState([])

  const [openBrandDropdown, setOpenBrandDropdown] = useState(false)

  const handleBrandMenu = (e) => {
    setOpenBrandDropdown(false)
    chooseBrand(e.target.textContent)
  }

  const handleBrandSearchChange = (e) => {
    const currentBrandList = startingBrandList
      .map((a) => a.toLowerCase())
      .filter((a) => a.includes(e.target.value.toLowerCase()))
    setBrandChoice(e.target.value)
    setUpdatedBrandList([...new Set(currentBrandList)])
    if (e.target.value.length > 0) {
      setOpenBrandDropdown(true)
    } else {
      setOpenBrandDropdown(false)
    }
  }

  const handleBrandSearchClick = () => {
    setOpenBrandDropdown(!openBrandDropdown)
    setUpdatedBrandList([
      ...new Set(
        startingBrandList
          .map((a) => a.toLowerCase())
          .sort(function (a, b) {
            const nameA = a.toUpperCase()
            const nameB = b.toUpperCase()
            if (nameA < nameB) {
              return -1
            } else {
              return 1
            }
          })
      ),
    ])
  }

  return (
    <div style={{ margin: '5px 0' }}>
      <InputGroup
        onChange={handleBrandSearchChange}
        onClick={handleBrandSearchClick}
      >
        <InputGroup.Text id='inputGroup-sizing-default'>Brand</InputGroup.Text>
        <Form.Control
          aria-label='Default'
          aria-describedby='inputGroup-sizing-default'
          placeholder='Type Brand Here...'
          onChange={handleBrandSearchChange}
          value={brandChoice}
        />
        {openBrandDropdown ? (
          <ul
            style={{
              position: 'absolute',
              listStyleType: 'none',
              padding: '0',
              margin: '38px 0 0 74px',
              alignItems: 'center',
              zIndex: '1',
            }}
          >
            {updatedBrandList.map((a, index) => (
              <li key={index} style={{ margin: '0', height: '40px' }}>
                <Card
                  style={{ height: '100%', width: '190.81px', padding: '0' }}
                  onClick={handleBrandMenu}
                >
                  <Card.Body
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {a}
                  </Card.Body>
                </Card>
              </li>
            ))}
          </ul>
        ) : null}
      </InputGroup>
    </div>
  )
}

export default BrandDropdown
