import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import {
  toTitleCase,
  resolveAfter1Second,
} from '../../../../../helpers/functions/regularFunctions.js'
import {
  apiGetCall,
  apiPostCall,
} from '../../../../../helpers/functions/proxyServer.js'

import Dropdowns from './postFoodComponents/Dropdowns.js'

const AddFoodToDb = ({ currentUserId }) => {
  const [loading, setLoading] = useState(false)
  const [foods, setFoods] = useState([])
  const [submitted, setSubmitted] = useState(true)
  useEffect(() => {
    setLoading(true)
    async function getCurrentFoods() {
      await apiGetCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/foods'
      )
        .then((response) => {
          setFoods(response.data)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    getCurrentFoods()
  }, [submitted])

  // Functions

  async function doReset() {
    const x = await resolveAfter1Second(10)
    setSubmitted(!submitted)
    return x
  }

  function resetAllFields() {
    setEditFoodCheck(false)
    setBrandChoice('')
    setFoodChoice('')
    setProteinChoice('')
    setCarbsChoice('')
    setFatChoice('')
    setCaloriesChoice('')
    setServingSizeChoice('')
    setServingSizeUnitChoice('')
    setDescriptionChoice('')
  }

  function LaunchUpdateSuccessModal(props) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ textAlign: 'center' }}
          >
            You Successfully Updated Your Food!
          </Modal.Title>
        </Modal.Header>
      </Modal>
    )
  }

  const deleteConfirmation = (e) => {
    setDeleteFoodId(e.currentTarget.id)
    setDeleteConfirmModal(true)
  }

  function LaunchDeleteConfirm(props, e) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ textAlign: 'center' }}
          >
            Are you sure you want to delete?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Button
              style={{
                width: '47%',
                height: '50px',
                marginLeft: '2%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={handleRemoveFoodFromDatabase}
            >
              Yes
            </Button>
            <Button
              style={{
                width: '47%',
                height: '50px',
                marginLeft: '2%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={props.onHide}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  // Variables

  const [filterFoods, setFilterFoods] = useState(true)
  const [editFoodCheck, setEditFoodCheck] = useState(false)
  const [updateSuccessModal, setUpdateSuccessModal] = useState(false)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [foodChoice, setFoodChoice] = useState('')
  const [brandChoice, setBrandChoice] = useState('')
  const [proteinChoice, setProteinChoice] = useState('')
  const [carbsChoice, setCarbsChoice] = useState('')
  const [fatChoice, setFatChoice] = useState('')
  const [caloriesChoice, setCaloriesChoice] = useState('')
  const [servingSizeChoice, setServingSizeChoice] = useState('')
  const [servingSizeUnitChoice, setServingSizeUnitChoice] = useState('')
  const [descriptionChoice, setDescriptionChoice] = useState('')
  const [deleteFoodId, setDeleteFoodId] = useState(-1)
  const [foodId, setFoodId] = useState()

  // Choose Functions

  const chooseFood = (e) => {
    setFoodChoice(e)
  }

  const chooseBrand = (e) => {
    setBrandChoice(e)
    setFilterFoods(!filterFoods)
  }

  // Handle Functions

  async function handlePostFoodSubmit() {
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/food/',
      {
        createdByUser: currentUserId,
        name: foodChoice,
        brand: brandChoice,
        servingSize: servingSizeChoice,
        servingSizeUnit: servingSizeUnitChoice,
        proteinGrams: proteinChoice,
        carbsGrams: carbsChoice,
        fatGrams: fatChoice,
        calories: caloriesChoice,
        description: descriptionChoice,
      }
    )
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        console.log(error)
      })

    await doReset()
    resetAllFields()
  }

  async function handleRemoveFoodFromDatabase() {
    let res = await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/delete-foods/',
      { ids: [deleteFoodId] }
    )
    console.log(res.data)
    setSubmitted(!submitted)
    doReset()
    setDeleteConfirmModal(false)
  }

  async function handleSaveFoodChanges() {
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/food/',
      {
        id: foodId,
        createdByUser: currentUserId,
        name: foodChoice,
        brand: brandChoice,
        servingSize: servingSizeChoice,
        servingSizeUnit: servingSizeUnitChoice,
        proteinGrams: proteinChoice,
        carbsGrams: carbsChoice,
        fatGrams: fatChoice,
        calories: caloriesChoice,
        description: descriptionChoice,
      }
    )
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        console.log(error)
      })

    await doReset()
    resetAllFields()
  }

  const handleEditFood = (e) => {
    const choice = foods.filter((a) => a._id === e.currentTarget.id)
    if (choice) {
      setFoodId(choice[0]._id)
      setBrandChoice(choice[0].brand)
      setFoodChoice(choice[0].name)
      setProteinChoice(choice[0].proteinGrams)
      setCarbsChoice(choice[0].carbsGrams)
      setFatChoice(choice[0].fatGrams)
      setCaloriesChoice(choice[0].calories)
      setServingSizeChoice(choice[0].servingSize)
      setServingSizeUnitChoice(choice[0].servingSizeUnit)
      setDescriptionChoice(choice[0].description)
      setEditFoodCheck(true)
    }
  }

  const handleClearEntry = (e) => {
    resetAllFields()
  }

  return (
    <div>
      <LaunchUpdateSuccessModal
        show={updateSuccessModal}
        onHide={() => setUpdateSuccessModal(false)}
      />
      <LaunchDeleteConfirm
        show={deleteConfirmModal}
        onHide={() => setDeleteConfirmModal(false)}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <div
            className='fa-5x'
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '100px 0 0 0',
            }}
          >
            <FontAwesomeIcon className='fa-spin' icon={faSpinner} />
          </div>
        ) : (
          <div style={{ margin: '0 5%', width: '405px' }}>
            <Dropdowns
              setProteinChoice={setProteinChoice}
              setCarbsChoice={setCarbsChoice}
              setFatChoice={setFatChoice}
              setCaloriesChoice={setCaloriesChoice}
              setServingSizeChoice={setServingSizeChoice}
              setServingSizeUnitChoice={setServingSizeUnitChoice}
              setDescriptionChoice={setDescriptionChoice}
              proteinChoice={proteinChoice}
              carbsChoice={carbsChoice}
              fatChoice={fatChoice}
              caloriesChoice={caloriesChoice}
              servingSizeChoice={servingSizeChoice}
              servingSizeUnitChoice={servingSizeUnitChoice}
              descriptionChoice={descriptionChoice}
              foods={foods}
              foodChoice={foodChoice}
              setFoodChoice={setFoodChoice}
              chooseFood={chooseFood}
              brandChoice={brandChoice}
              setBrandChoice={setBrandChoice}
              chooseBrand={chooseBrand}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant='outline-dark'
                style={{ width: '48%', margin: '5px 0' }}
                onClick={handleClearEntry}
              >
                Clear Entry
              </Button>
              {!editFoodCheck ? (
                <Button
                  variant='outline-dark'
                  style={{ width: '48%', margin: '5px 0 5px 4%' }}
                  onClick={handlePostFoodSubmit}
                >
                  Submit Food
                </Button>
              ) : (
                <Button
                  variant='outline-dark'
                  style={{ width: '48%', margin: '5px 0 5px 4%' }}
                  onClick={handleSaveFoodChanges}
                >
                  Save Changes
                </Button>
              )}
            </div>
            <div style={{ width: '100%' }}>
              <h1
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                {'Foods Added By You Will Appear Below!'}
              </h1>
              <ul
                style={{
                  listStyleType: 'none',
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxHeight: '250px',
                  overflowY: 'scroll',
                  margin: '0',
                }}
              >
                {foods
                  .filter((b) => b.createdByUser._id === currentUserId)
                  .sort(
                    (a, b) =>
                      a.brand.localeCompare(b.brand) ||
                      a.name.localeCompare(b.name)
                  )
                  .map((a) => (
                    <li
                      key={a._id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '2px 2%',
                        width: '96%',
                      }}
                    >
                      <Card
                        className='border-0'
                        style={{
                          width: '25%',
                          margin:
                            toTitleCase(a.brand.toLowerCase()).length < 15
                              ? '10px 0 10px 0'
                              : '0 0 00',
                          fontSize: '12px',
                          textAlign: 'center',
                          height: '20px',
                        }}
                      >
                        <Card.Body
                          style={{
                            width: '95%',
                            padding: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {toTitleCase(a.brand.toLowerCase())}
                        </Card.Body>
                      </Card>
                      <Card
                        className='border-0'
                        style={{
                          width: '25%',
                          margin: '10px 0 10px 0',
                          fontSize: '12px',
                          textAlign: 'center',
                          height: '20px',
                        }}
                      >
                        <Card.Body
                          style={{
                            width: '95%',
                            padding: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {toTitleCase(a.name.toLowerCase())}{' '}
                        </Card.Body>
                      </Card>
                      <Card
                        className='border-0'
                        style={{
                          width: '20%',
                          margin: '10px 0 10px 0',
                          fontSize: '12px',
                          height: '20px',
                        }}
                      >
                        <Card.Body
                          style={{
                            width: '95%',
                            padding: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {a.calories} Calories{' '}
                        </Card.Body>
                      </Card>
                      <Button
                        className='border-0'
                        variant='outline-dark'
                        style={{ width: '15%' }}
                        id={a._id}
                        onClick={handleEditFood}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        className='border-0'
                        variant='outline-dark'
                        style={{ width: '15%' }}
                        id={a._id}
                        onClick={deleteConfirmation}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddFoodToDb
