import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

const SetsDropdown = ({ setsChoice, chooseSets, chooseWeightUnit, weightUnitChoice, chooseExerEntryInfoPost, setsToPost, chooseDropdown }) => {

    const setsList = ['Select # Of Sets', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

    const weightUnitList = ['Pounds', 'Kilograms']
    
    const [openWeightUnitDropdown, setOpenWeightUnitDropdown] = useState(false)

    const handleOpenWeightUnitDropdown = () => {
        setOpenWeightUnitDropdown(!openWeightUnitDropdown)
    }

    const handleWeightUnitMenu = (e) => {
        setOpenWeightUnitDropdown(false)
        chooseWeightUnit(e)
    }
    
    const [openSetsDropdown, setOpenSetsDropdown] = useState(false)

    const handleOpenSetsDropdown = () => {
        setOpenSetsDropdown(!openSetsDropdown)        
        chooseDropdown('closeBoth')
    }

    const handleSetsMenu = (e) => {
        setOpenSetsDropdown(false)
        chooseSets(e)
    }

    const [repsChoice, setRepsChoice] = useState('')

    const [weightChoice, setWeightChoice] = useState('')

    const handleRepsInputChange = a => (e) => {
        setRepsChoice(e.target.value)
        const exerEntryInfoPost = {
            setNumber: Number(a.setNumber),
            numberReps: Number(e.target.value),
            weight: Number(weightChoice),
            weightUnit: 'Pounds'
        }
        chooseExerEntryInfoPost(exerEntryInfoPost, a.setNumber)
    }

    const handleWeightInputChange = a => (e) => {
        setWeightChoice(e.target.value)
        const exerEntryInfoPost = {
            setNumber: Number(a.setNumber),
            numberReps: Number(repsChoice),
            weight: Number(e.target.value),
            weightUnit: 'Pounds'
        }
        chooseExerEntryInfoPost(exerEntryInfoPost, a.setNumber)
    }

    return (
        <div>
            <div style = {{ display: 'flex', flexDirection: 'row', width: '50%', margin: '0 25%'}}>
                <DropdownButton
                    id = 'dropdown-basic-button'
                    variant = 'outline-dark'
                    style = { setsChoice === 'Select # Of Sets' ? { margin: '10px 10%' }  : { margin: '10px 35% 7px'}}
                    title = {setsChoice} 
                    onClick = {handleOpenSetsDropdown}
                >
                    {openSetsDropdown ? (
                            setsList.map((a, index) => 
                                <Dropdown.Item key = {index} onClick = {handleSetsMenu}>{a}</Dropdown.Item> 
                            )
                        ) : null
                    }    
                </DropdownButton>
            </div>
            <div style = {{ width: '80%', margin: '0 10%' }}>
                <ul style = {{ listStyleType: 'none', padding: '0' }}>
                {setsToPost.map((a, index) => 
                <li key = {index}>
                    <InputGroup
                        style = {{ width: '90%', margin: '5px 5%' }}
                    >
                        <InputGroup.Text id = 'inputGroup-sizing-default'>
                            Set {a.setNumber}
                        </InputGroup.Text>
                        <Form.Control
                            aria-label = 'Default'
                            aria-describedby = 'inputGroup-sizing-default' 
                            placeholder = '# Reps...'
                            style = {{ fontSize: '14px' }}
                            onChange = {handleRepsInputChange(a)}
                        />
                        <Form.Control
                            aria-label = 'Default'
                            aria-describedby = 'inputGroup-sizing-default' 
                            placeholder = 'Weight...'
                            style = {{ fontSize: '14px' }}
                            onChange = {handleWeightInputChange(a)}
                        />
                    </InputGroup>
                </li>
                )}
                </ul> 
            </div>
            <DropdownButton
                id = 'dropdown-basic-button'
                variant = 'outline-dark' 
                style = {{ marginLeft: '37%' }}
                title = {weightUnitChoice} 
                onClick = {handleOpenWeightUnitDropdown}
            >
                {openWeightUnitDropdown ? (
                        weightUnitList.map((a, index) => 
                            <Dropdown.Item key = {index} onClick = {handleWeightUnitMenu}>{a}</Dropdown.Item> 
                        )
                    ) : null
                }    
            </DropdownButton>
        </div>
    )
}

export default SetsDropdown
