import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
//import DeviceEmulator from 'react-device-emulator';
import 'react-device-emulator/lib/styles/style.css'
import { Container } from 'react-bootstrap'
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './pages/PrivateRoute'
import Profile from './pages/Profile'
import Summary from './pages/Summary'
import PostExercises from './pages/PostExercises'
import Signup from './pages/Signup'
import Login from './pages/Login'
import Home from './pages/Home'

function App() {
  // <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-device-emulator/styles/style.css" />

  return (
    <div>
      {/* <DeviceEmulator type="mobile" withDeviceSwitch withRotator url="localhost:3000"> */}
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              exact
              path='/signup'
              element={
                <Container className='bootstrap-container'>
                  <Signup />
                </Container>
              }
            />
            <Route
              exact
              path='/login'
              element={
                <Container className='bootstrap-container'>
                  <Login />
                </Container>
              }
            />
            <Route
              exact
              path='/profile'
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/'
              element={
                <PrivateRoute>
                  <Summary />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/post/exercises'
              element={
                <PrivateRoute>
                  <PostExercises />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='/home'
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      {/* </DeviceEmulator> */}
    </div>
  )
}

export default App
