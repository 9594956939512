import React from 'react'
import Card from 'react-bootstrap/Card'
import ProgressBar from 'react-bootstrap/ProgressBar'

const MealTotals = ({ mealEntries, foods, dateSearchInput, user }) => {
  function usersGoals() {
    if (user) {
      return {
        cal: user.goalDailyCaloriesEaten,
        p: user.goalDailyProteinGrams,
        c: user.goalDailyCarbGrams,
        f: user.goalDailyFatGrams,
      }
    } else {
      return {
        cal: 0,
        p: 0,
        c: 0,
        f: 0,
      }
    }
  }

  function InfoProgressBar() {
    const now = 100

    return (
      <ProgressBar
        now={now}
        label={`Progress`}
        style={{ width: '75%', marginTop: '5px' }}
      />
    )
  }

  function CaloriesProgressBar() {
    let usersDailyCaloriesGoal = user ? user.goalDailyCaloriesEaten : 0

    const percentCalOver =
      ((totalCalories - user.goalDailyCaloriesEaten) /
        user.goalDailyCaloriesEaten) *
        100 >
      0
        ? 30
        : 0

    const now =
      percentCalOver <= 0 ? (totalCalories / usersDailyCaloriesGoal) * 100 : 70

    return (
      <ProgressBar style={{ width: '75%', marginTop: '5px' }}>
        <ProgressBar now={now} label={`${totalCalories} cal`} />
        <ProgressBar
          now={percentCalOver}
          variant='danger'
          label={`${totalCalories - user.goalDailyCaloriesEaten} cal over`}
        />
      </ProgressBar>
    )
  }

  function ProteinProgressBar() {
    let usersDailyProteinGoal = user ? user.goalDailyProteinGrams : 0

    const percentProteinOver =
      ((totalProtein - usersDailyProteinGoal) / user.goalDailyProteinGrams) *
        100 >
      0
        ? 30
        : 0

    const now =
      percentProteinOver <= 0
        ? (totalProtein / usersDailyProteinGoal) * 100
        : 70

    return (
      <ProgressBar style={{ width: '75%', marginTop: '5px' }}>
        <ProgressBar now={now} label={`${totalProtein} g`} />
        <ProgressBar
          now={percentProteinOver}
          variant='danger'
          label={`${totalProtein - usersDailyProteinGoal} g over`}
        />
      </ProgressBar>
    )
  }

  function CarbsProgressBar() {
    let usersDailyCarbGoal = user ? user.goalDailyCarbGrams : 0

    const percentCarbOver =
      ((totalCarbs - usersDailyCarbGoal) / usersDailyCarbGoal) * 100 > 0
        ? 30
        : 0

    const now =
      percentCarbOver <= 0 ? (totalCarbs / usersDailyCarbGoal) * 100 : 70

    return (
      <ProgressBar style={{ width: '75%', marginTop: '5px' }}>
        <ProgressBar now={now} label={`${totalCarbs} g`} />
        <ProgressBar
          now={percentCarbOver}
          variant='danger'
          label={`${totalCarbs - usersDailyCarbGoal} g over`}
        />
      </ProgressBar>
    )
  }
  function FatProgressBar() {
    let usersDailyFatGoal = user ? user.goalDailyFatGrams : 0

    const percentFatOver =
      ((totalFat - usersDailyFatGoal) / user.goalDailyFatGrams) * 100 > 0
        ? 30
        : 0

    const now = percentFatOver <= 0 ? (totalFat / usersDailyFatGoal) * 100 : 70

    return (
      <ProgressBar style={{ width: '75%', marginTop: '5px' }}>
        <ProgressBar now={now} label={`${totalFat} g`} />
        <ProgressBar
          now={percentFatOver}
          variant='danger'
          label={`${totalFat - usersDailyFatGoal} g over`}
        />
      </ProgressBar>
    )
  }

  const items = mealEntries
    .filter((c) => String(c.mealDatetime).includes(dateSearchInput))
    .map((item) => ({
      calories: foods
        .filter((a) => a._id === item.food._id)
        .map((n) => Number(item.servingsEaten * n.calories)),
      protein: foods
        .filter((a) => a._id === item.food._id)
        .map((n) => Number(item.servingsEaten * n.proteinGrams)),
      carbs: foods
        .filter((a) => a._id === item.food._id)
        .map((n) => Number(item.servingsEaten * n.carbsGrams)),
      fat: foods
        .filter((a) => a._id === item.food._id)
        .map((n) => Number(item.servingsEaten * n.fatGrams)),
      id: item.id,
    }))

  const totalCalories = items
    .map((a) => a.calories[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const totalProtein = items
    .map((a) => a.protein[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const totalCarbs = items
    .map((a) => a.carbs[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  const totalFat = items
    .map((a) => a.fat[0])
    .reduce(function (a, b) {
      return a + b
    }, 0)
    .toFixed(0)

  return (
    <div style={{ width: '100%', margin: '5px 0' }}>
      <Card style={{ backgroundColor: '#c0e2f9' }}>
        <Card.Body className='text-center'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InfoProgressBar />
            <h1 style={{ fontSize: '12px', margin: '5px' }}>{'Goals'}</h1>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CaloriesProgressBar />
            <h1 style={{ fontSize: '12px', margin: '5px' }}>{`${
              usersGoals().cal
            } cal`}</h1>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ProteinProgressBar />
            <h1 style={{ fontSize: '12px', margin: '5px' }}>{`${
              usersGoals().p
            }g protein`}</h1>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CarbsProgressBar />
            <h1 style={{ fontSize: '12px', margin: '5px' }}>{`${
              usersGoals().c
            }g carbs`}</h1>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FatProgressBar />
            <h1 style={{ fontSize: '12px', margin: '5px' }}>{`${
              usersGoals().f
            }g fat`}</h1>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default MealTotals
