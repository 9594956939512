import React from 'react'
import Cardio from './ExerciseTypes/Cardio'
import Weights from './ExerciseTypes/Weights'

const Exercises = ({
  dateSearchInput,
  exercises,
  exerciseEntries,
  exerciseEntryInfo,
  chooseDeleteExerciseEntryId,
  dayOfWeek,
  submitted,
  setSubmitted,
  currentUserId,
}) => {
  return (
    <div style={{ width: '90%', margin: '5px 5%' }}>
      <Weights
        exercises={exercises}
        exerciseEntries={exerciseEntries}
        exerciseEntryInfo={exerciseEntryInfo}
        dateSearchInput={dateSearchInput}
        chooseDeleteExerciseEntryId={chooseDeleteExerciseEntryId}
        dayOfWeek={dayOfWeek}
        submitted={submitted}
        setSubmitted={setSubmitted}
        currentUserId={currentUserId}
      />
      <Cardio
        exercises={exercises}
        exerciseEntries={exerciseEntries}
        exerciseEntryInfo={exerciseEntryInfo}
        dateSearchInput={dateSearchInput}
        chooseDeleteExerciseEntryId={chooseDeleteExerciseEntryId}
        dayOfWeek={dayOfWeek}
        submitted={submitted}
        setSubmitted={setSubmitted}
        currentUserId={currentUserId}
      />
    </div>
  )
}

export default Exercises
