import React, { useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

const MuscleGroupDropdown = ({ muscleGroupChoice, setMuscleGroupChoice, chooseMuscleGroup, exercises, openMuscleGroupDropdown, setOpenMuscleGroupDropdown, chooseDropdown }) => {

    const startingMuscleGroupList = exercises.map(a => a.muscleGroup)
    
    const [updatedMuscleGroupList, setUpdatedMuscleGroupList] = useState([])

    const handleMuscleGroupSearchChange = (e) => {
        const currentMuscleGroupList = startingMuscleGroupList.map(a => a.toLowerCase()).filter(a => a.includes(e.target.value.toLowerCase()))
        
        setMuscleGroupChoice(e.target.value)
        
        setUpdatedMuscleGroupList([...new Set(currentMuscleGroupList)])
        
        if(e.target.value.length > 0){
            setOpenMuscleGroupDropdown(true)
            chooseDropdown('closeExercise')
        } else {
            setOpenMuscleGroupDropdown(false)
        }
    }

    const handleMuscleGroupSearchClick = (e) => {
        if(openMuscleGroupDropdown === false){
            chooseDropdown('closeExercise')
        }
        setOpenMuscleGroupDropdown(!openMuscleGroupDropdown)
        setUpdatedMuscleGroupList([...new Set(startingMuscleGroupList)])
    }

    const handleMuscleGroupMenu = (e) => {
        setOpenMuscleGroupDropdown(false)
        chooseMuscleGroup(e)
    }

    return (
        <div style = {{ marginTop: '10px' }}>
            <InputGroup
                style = {{ width: '80%', margin: '0 10%' }}
                onClick={handleMuscleGroupSearchClick}
            >
                <InputGroup.Text id = 'inputGroup-sizing-default'>
                    Muscle Group
                </InputGroup.Text>
                <Form.Control
                    aria-label = 'Default'
                    aria-describedby = 'inputGroup-sizing-default' 
                    placeholder = 'Type Muscle Group Here...'
                    style = {{ fontSize: '14px' }}
                    value = {muscleGroupChoice}
                    onChange={handleMuscleGroupSearchChange}
                />
                {openMuscleGroupDropdown ? (
                    <ul style = {{ position: 'absolute', listStyleType: 'none', padding: '0', margin: '37.81px 0 0 125px', alignItems: 'center', zIndex: '1' }}>
                        {updatedMuscleGroupList.map((a, index) => 
                        <li key = {index} style = {{ margin: '0', height: '40px' }}>
                            <Card style = {{ height: '100%', padding: '0' }} onClick = {handleMuscleGroupMenu}>
                                <Card.Body style = {{ height: '100%', display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                    {a}
                                </Card.Body>
                            </Card>
                        </li>
                        )}
                    </ul>
                    ) : null
                }
            </InputGroup>
        </div>
    )
}

export default MuscleGroupDropdown
