import React from 'react'
import ExerciseDropdown from './Dropdowns/ExerciseDropdown'
import MuscleGroupDropdown from './Dropdowns/MuscleGroupDropdown'

const Dropdowns = ({ exercises, exerciseChoice, setExerciseChoice, chooseExercise, chooseMuscleGroup, muscleGroupChoice, setMuscleGroupChoice, workoutType }) => {

    return (
        <div>
            {workoutType === 1 ? (
                <div>
                    <ExerciseDropdown
                        exercises = {exercises.filter(a => a.category.toLowerCase() === 'weight lifting')}
                        chooseExercise = {chooseExercise}
                        exerciseChoice = {exerciseChoice}
                        setExerciseChoice = {setExerciseChoice}
                    />
                    <MuscleGroupDropdown
                        muscleGroupChoice = {muscleGroupChoice}
                        setMuscleGroupChoice = {setMuscleGroupChoice}
                        chooseMuscleGroup = {chooseMuscleGroup}
                        exercises = {exercises}
                    />
                </div>
            ) : workoutType === 0 ? (
                <div>
                    <ExerciseDropdown
                        exercises = {exercises.filter(a => a.category.toLowerCase() === 'cardio')}
                        chooseExercise = {chooseExercise}
                        exerciseChoice = {exerciseChoice}
                        setExerciseChoice = {setExerciseChoice}
                    />
                </div>
            ) : null
            }
        </div>
    )
}

export default Dropdowns
