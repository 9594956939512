import React, { useContext, createContext, useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../Firebase'
import { auth } from '../Firebase'
import { subtractHours } from '../helpers/functions/regularFunctions'

const docSnap = await getDocs(collection(db, 'users'))

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState('')
  const [currentUserId, setCurrentUserId] = useState(-1)
  const [currentUserEmail, setCurrentUserEmail] = useState('')
  const [currentUserFirstName, setCurrentUserFirstName] = useState('')
  const [currentUserLastName, setCurrentUserLastName] = useState('')
  const [currentUserHydration, setCurrentUserHydration] = useState([])
  const [loading, setLoading] = useState(true)
  const timezoneOffset = new Date().getTimezoneOffset() / 60
  const dateProp = subtractHours(new Date(), timezoneOffset)
    .toJSON()
    .slice(0, 10)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  useEffect(() => {
    const userIds = []

    docSnap.forEach((a) => {
      userIds.push(a.data())
    })

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      if (user) {
        setCurrentUserEmail(
          userIds
            .filter((a) => a.email.toLowerCase() === user.email.toLowerCase())
            .map((b) => b.email)[0]
        )
        setCurrentUserId(
          userIds
            .filter((a) => a.email.toLowerCase() === user.email.toLowerCase())
            .map((b) => b.id)[0]
        )
        setCurrentUserFirstName(
          userIds
            .filter((a) => a.email.toLowerCase() === user.email.toLowerCase())
            .map((b) => b.firstName)[0]
        )
        setCurrentUserLastName(
          userIds
            .filter((a) => a.email.toLowerCase() === user.email.toLowerCase())
            .map((b) => b.lastName)[0]
        )
        setCurrentUserHydration([
          {
            date: dateProp,
            cupsOfWaterDrank: 0,
          },
        ])
      }
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    currentUserId,
    currentUserEmail,
    currentUserFirstName,
    currentUserLastName,
    currentUserHydration,
    signup,
    login,
    logout,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
