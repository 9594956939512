import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faXmark,
  faEdit,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons'
import { apiPostCall } from '../../../../helpers/functions/proxyServer'

const InfoModals = ({
  entryModal,
  setEntryModal,
  entryInfo,
  submitted,
  setSubmitted,
  ingredients,
}) => {
  async function postUpdatedMeal() {
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal-entry/',
      {
        id: entryInfo.id,
        category: newCategoryChoice,
        mealDay: newDayChoice,
        mealDatetime: `${newDatetimeChoice}T00:00:00.000Z`,
        servingsEaten: newServingsChoice,
      }
    )
      .then(function (response) {
        console.log(response)
        setConfirmSaveModal(false)
        setEntryModal(false)
        setSubmitted(!submitted)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  function undoChanges() {
    setNewDatetimeChoice(entryInfo.entryDatetime)
    setNewCategoryChoice(entryInfo.category)
    setNewServingsChoice(entryInfo.servingsEaten)
    setNewDayChoice(entryInfo.entryDay)
    setConfirmSaveModal(false)
  }

  function LaunchConfirmSaveModal(props) {
    return (
      <Modal {...props} size='sm' style={{ marginTop: '110%' }}>
        <Modal.Header closeButton>
          <Modal.Title
            id='contained-modal-title-vcenter'
            style={{ textAlign: 'center', fontSize: '14px' }}
          >
            Are you sure? This will permanently change your meal entry!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{
                width: '47%',
                height: '50px',
                marginLeft: '2%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={postUpdatedMeal}
            >
              Yes
            </Button>
            <Button
              id='undoChanges'
              style={{
                width: '47%',
                height: '50px',
                marginLeft: '2%',
                fontSize: '14px',
              }}
              variant='outline-dark'
              onClick={undoChanges}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [editServings, setEditServings] = useState(false)
  const [editEntryDatetime, setEditEntryDatetime] = useState(false)
  const [editCategory, setEditCategory] = useState(false)
  const [confirmSaveModal, setConfirmSaveModal] = useState(false)
  const [newServingsChoice, setNewServingsChoice] = useState(
    entryInfo.servingsEaten
  )
  const [newDayChoice, setNewDayChoice] = useState(entryInfo.entryDay)
  const [newDatetimeChoice, setNewDatetimeChoice] = useState(
    entryInfo.entryDatetime
  )
  const [newCategoryChoice, setNewCategoryChoice] = useState(entryInfo.category)

  const handleServingsChange = (e) => {
    setNewServingsChoice(e.target.value)
  }

  const handleDatetimeChange = (e) => {
    let a = e.target.value
    const d = new Date(
      a.split('-')[0],
      Number(a.split('-')[1]) - 1,
      a.split('-')[2]
    )
    setNewDatetimeChoice(e.target.value)
    if (a.length === 10) {
      setNewDayChoice(d.toLocaleString('en-us', { weekday: 'long' }))
    }
  }

  const categoryList = ['Breakfast', 'Lunch', 'Dinner', 'Snacks']

  const [openCategoryDropdown, setOpenCategoryDropdown] = useState(false)

  const handleOpenCategoryDropdown = () => {
    setOpenCategoryDropdown(true)
  }

  const handleCategoryMenu = (e) => {
    setOpenCategoryDropdown(false)
    setNewCategoryChoice(e.target.textContent)
  }

  return (
    <div>
      <LaunchConfirmSaveModal
        show={confirmSaveModal}
        onHide={() => setConfirmSaveModal(false)}
      />
      <Modal
        show={entryModal}
        onHide={() => setEntryModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Modal.Title
              style={{ width: '60%', textAlign: 'center', marginLeft: '20%' }}
            >
              {entryInfo.brand.toLowerCase() === 'homemade'
                ? `${entryInfo.foodName}`
                : `${entryInfo.brand}, ${entryInfo.foodName}`}
            </Modal.Title>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', marginLeft: '10%' }}
              onClick={() => setEntryModal(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px 0 0 0' }}>
          <div>
            <ul
              style={{
                listStyleType: 'none',
                padding: '0',
                textAlign: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div id='saveButton' style={{ width: '20%' }}>
                  {(editEntryDatetime || editCategory || editServings) && (
                    <Button
                      style={{
                        width: '95%',
                        height: '90%',
                        border: '0',
                        padding: '0',
                        margin: '10px 0 0 5px',
                      }}
                      variant='outline-dark'
                      onClick={() =>
                        setEditEntryDatetime(false) +
                        setEditCategory(false) +
                        setEditServings(false) +
                        setConfirmSaveModal(true)
                      }
                    >
                      <FontAwesomeIcon className='fa-3x' icon={faFloppyDisk} />
                    </Button>
                  )}
                </div>
                <div style={{ width: '80%' }}>
                  <li style={{ marginTop: '2px' }}>
                    {!editEntryDatetime ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          marginLeft: '10%',
                        }}
                      >
                        <p
                          style={{
                            width: '70%',
                            fontSize: '14px',
                            height: '14px',
                            paddingTop: '4px',
                          }}
                        >
                          {`${newDayChoice}, ${newDatetimeChoice}`}
                        </p>
                        <Button
                          style={{
                            width: '20%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() => setEditEntryDatetime(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          marginLeft: '10%',
                        }}
                      >
                        <InputGroup style={{ width: '70%', height: '30px' }}>
                          <InputGroup.Text
                            style={{
                              fontSize: '14px',
                              width: '45%',
                              height: '30px',
                              textAlign: 'center',
                              padding: '0 0 0 7px',
                            }}
                          >
                            {newDayChoice}
                          </InputGroup.Text>
                          <Form.Control
                            style={{
                              fontSize: '14px',
                              width: '55%',
                              height: '30px',
                              textAlign: 'center',
                            }}
                            aria-label='Servings'
                            onChange={handleDatetimeChange}
                            value={newDatetimeChoice}
                          />
                        </InputGroup>
                        <Button
                          style={{
                            width: '20%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() =>
                            setEditEntryDatetime(false) +
                            setNewDatetimeChoice(entryInfo.entryDatetime)
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}
                  </li>
                  <li style={{ marginTop: '2px' }}>
                    {!editCategory ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          marginLeft: '10%',
                        }}
                      >
                        <p
                          style={{
                            width: '70%',
                            fontSize: '14px',
                            height: '14px',
                            paddingTop: '4px',
                          }}
                        >
                          {newCategoryChoice}
                        </p>
                        <Button
                          style={{
                            width: '20%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() => setEditCategory(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          margin: '4px 0 0 10%',
                          height: '40px',
                        }}
                      >
                        <DropdownButton
                          variant='outline-dark'
                          id='dropdown-basic-button'
                          title={newCategoryChoice}
                          onClick={handleOpenCategoryDropdown}
                          style={{ width: '70%', height: '30px' }}
                        >
                          {openCategoryDropdown
                            ? categoryList.map((a, index) => (
                                <Dropdown.Item
                                  key={index}
                                  style={{ margin: '0', paddingBottom: '0' }}
                                  onClick={handleCategoryMenu}
                                >
                                  {a}
                                </Dropdown.Item>
                              ))
                            : null}
                        </DropdownButton>
                        <Button
                          style={{
                            width: '20%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            margin: '3px 0 0 2%',
                          }}
                          variant='outline-dark'
                          onClick={() =>
                            setEditCategory(false) +
                            setNewCategoryChoice(entryInfo.category)
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}
                  </li>
                  <li style={{ marginTop: '2px' }}>
                    {!editServings ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          marginLeft: '10%',
                        }}
                      >
                        <p
                          style={{
                            width: '70%',
                            fontSize: '14px',
                            height: '14px',
                            paddingTop: '4px',
                          }}
                        >
                          {`${newServingsChoice} servings`}
                        </p>
                        <Button
                          style={{
                            width: '20%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() => setEditServings(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          marginLeft: '10%',
                        }}
                      >
                        <InputGroup style={{ width: '70%', height: '30px' }}>
                          <Form.Control
                            style={{
                              fontSize: '14px',
                              width: '35%',
                              height: '30px',
                              textAlign: 'center',
                            }}
                            aria-label='Servings'
                            onChange={handleServingsChange}
                            value={newServingsChoice}
                          />
                          <InputGroup.Text
                            style={{
                              fontSize: '14px',
                              width: '65%',
                              height: '30px',
                              textAlign: 'center',
                              padding: '0 0 0 7px',
                            }}
                          >
                            servings
                          </InputGroup.Text>
                        </InputGroup>
                        <Button
                          style={{
                            width: '20%',
                            height: '30px',
                            border: '0',
                            padding: '0',
                            marginLeft: '2%',
                          }}
                          variant='outline-dark'
                          onClick={() =>
                            setEditServings(false) +
                            setNewServingsChoice(entryInfo.servingsEaten)
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}
                  </li>
                </div>
              </div>
            </ul>
            <ul style={{ listStyle: 'none', padding: '0' }}>
              <h1
                style={{
                  fontSize: '12px',
                  margin: '10px',
                  textAlign: 'center',
                }}
              >
                {`Calories: ${entryInfo.calories} - Protein: ${entryInfo.protein} - Carbs: ${entryInfo.carbs} - Fat: ${entryInfo.fat}`}
              </h1>
              {entryInfo.homemade &&
                entryInfo.ingredients.map((a, index) => (
                  <li
                    key={index}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <h1
                      style={{
                        fontSize: '12px',
                        margin: '10px 0',
                        paddingLeft: '10px',
                        width: '25%',
                        textAlign: 'center',
                      }}
                    >
                      {`${
                        Number(a.numServings) *
                        newServingsChoice *
                        ingredients.filter(
                          (ing) => ing.id === Number(a.ingredientId)
                        )[0].servingSize
                      } ${
                        ingredients.filter(
                          (ing) => ing.id === Number(a.ingredientId)
                        )[0].servingSizeUnit
                      }`}
                    </h1>
                    <h1
                      style={{
                        fontSize: '12px',
                        margin: '10px 0',
                        paddingLeft: '10px',
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >
                      {`${
                        ingredients.filter(
                          (ing) => ing.id === Number(a.ingredientId)
                        )[0].name
                      }`}
                    </h1>
                    <h1
                      style={{
                        fontSize: '12px',
                        margin: '10px 0',
                        paddingLeft: '10px',
                        width: '25%',
                        textAlign: 'center',
                      }}
                    >
                      {`(${
                        Number(a.numServings) *
                        newServingsChoice *
                        ingredients.filter(
                          (ing) => ing.id === Number(a.ingredientId)
                        )[0].calories
                      } calories)`}
                    </h1>
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InfoModals
