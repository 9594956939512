import React from 'react'

import AppNavbar from './AppNavbar.js'

const Home = () => {
  return (
    <div>
      <AppNavbar />
      <div
        className='fa-5x'
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '100px 0 0 0',
        }}
      >
        Welcome!
      </div>
    </div>
  )
}

export default Home
