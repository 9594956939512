import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from 'react-bootstrap/Card'

const FoodDropdown = ({
  foods,
  foodChoice,
  setFoodChoice,
  chooseFood,
  brandChoice,
}) => {
  const startingFoodList = foods.map((a) => a.name)

  const [updatedFoodList, setUpdatedFoodList] = useState([])

  const [openFoodDropdown, setOpenFoodDropdown] = useState(false)

  const handleFoodMenu = (e) => {
    setOpenFoodDropdown(false)
    chooseFood(e.target.textContent)
  }

  const handleFoodSearchChange = (e) => {
    const currentFoodList = startingFoodList
      .map((a) => a.toLowerCase())
      .filter((a) => a.includes(e.target.value.toLowerCase()))

    setFoodChoice(e.target.value)

    setUpdatedFoodList([...new Set(currentFoodList)])

    if (e.target.value.length > 0) {
      setOpenFoodDropdown(true)
    } else {
      setOpenFoodDropdown(false)
    }
  }

  const handleSearchFoodsClick = (e) => {
    if (brandChoice === '') {
      setUpdatedFoodList(foods.map((a) => a.name))
      setOpenFoodDropdown(!openFoodDropdown)
    }
    if (brandChoice !== '') {
      setUpdatedFoodList(
        foods
          .filter((a) => a.brand.toLowerCase() === brandChoice.toLowerCase())
          .map((b) => b.name)
      )
      setOpenFoodDropdown(!openFoodDropdown)
    }
  }

  return (
    <div style={{ margin: '5px 0' }}>
      <InputGroup
        onChange={handleFoodSearchChange}
        onClick={handleSearchFoodsClick}
      >
        <InputGroup.Text id='inputGroup-sizing-default'>Food</InputGroup.Text>
        <Form.Control
          aria-label='Default'
          aria-describedby='inputGroup-sizing-default'
          placeholder='Type Food Name Here...'
          onChange={handleFoodSearchChange}
          value={foodChoice}
        />
        {openFoodDropdown ? (
          <ul
            style={{
              position: 'absolute',
              listStyleType: 'none',
              padding: '0',
              margin: '38px 0 0 74px',
              alignItems: 'center',
              zIndex: '1',
            }}
          >
            {updatedFoodList.map((a, index) => (
              <li key={index} style={{ margin: '0', height: '40px' }}>
                <Card
                  style={{ height: '100%', width: '190.81px', padding: '0' }}
                  onClick={handleFoodMenu}
                >
                  <Card.Body
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    {a}
                  </Card.Body>
                </Card>
              </li>
            ))}
          </ul>
        ) : null}
      </InputGroup>
    </div>
  )
}

export default FoodDropdown
